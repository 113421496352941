@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;800&display=swap");

@font-face {
  font-family: "Random Grotesque Standard Bold";
  src: url("../font/RG-StandardBold.woff2") format("woff2"),
    url("../font/RG-StandardBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Random Grotesque Slim Book";
  src: url("../font/RG-SlimBook.woff2") format("woff2"),
    url("../font/RG-SlimBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Random Grotesque Standard";
  src: url("../font/RG-StandardBold.woff2") format("woff2"),
    url("../font/RG-StandardBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Random Grotesque Standard Medium";
  src: url("../font/RG-StandardMedium.woff2") format("woff2"),
    url("../font/RG-StandardMedium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Random Grotesque Standard Book";
  src: url("../font/RG-StandardBook.woff2") format("woff2"),
    url("../font/RG-StandardBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Random Grotesque Spacious Book";
  src: url("../font/RG-SpaciousBook.woff2") format("woff2"),
    url("../font/RG-SpaciousBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

:root {
  --color-primary: #5b7fbd;
  --color-secondary: #df6dab;
  --font-family-bold: "Random Grotesque Standard";
  --font-family-normal: "Random Grotesque Standard";
  --font-family-500: "Random Grotesque Standard Medium";
  --font-family-400: "Random Grotesque Standard Book";
  --font-family-300: "Random Grotesque Spacious Book";
}

a {
  text-decoration: none;
  color: #5b7fbd;
}

a:hover {
  color: #ea409c;
}

.hambarger-menu-header-wrap {
  cursor: pointer;
  margin: auto;
  display: none;
}

.hambarger-menu-header-wrap .hambarger-menu-header {
  max-width: 25px;
  margin: 0 auto;
  display: block;
}

.hambarger-menu-header-wrap .hambarger-menu-header .line {
  width: 100%;
  height: 3px;
  background-color: #5b7fbd;
  display: block;
  margin-bottom: 5px;
}

.hambarger-menu-header-wrap .hambarger-menu-header .line:last-child {
  margin-bottom: 0;
}

.hambarger-menu-header-wrap .text {
  color: #5b7fbd;
  text-transform: uppercase;
  font-size: 13px;
}

.hambarger-menu-header-wrap:hover .hambarger-menu-header .line {
  background-color: #ea409c;
}

/* .logo{
    width: 150px;
  } */
.bgcolor {
  background-color: #f8ce65;
}

/* li a.active {
    color: #ffff ;
    font-size: 20px;
  } */
li a.active {
  color: #ffff !important;
}

li a {
  color: #ffff;
  font-size: 20px;
}

.main-nav-menu .nav-menu li.has_submenu ul {
  background-color: #f8ce65 !important;
  border: 1px solid black !important;
}

.bannerContainer {
  background-image: url("./assets/image/bannerimage.png");
  height: 100vh;
  /* background: url(mountain.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
}

.cardcontainer {
  margin: "20% auto";
}

.carousel-item {
  position: relative;
  text-align: center;
  color: white;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

.carousel-content {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-container {
  background-image: url("./assets/image/bannerimage.png");
  height: 100vh;
  /* background: url(mountain.jpg); */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-content {
  text-align: center;
  color: white;
  padding: 20px;
}

.feature-three-blogs-w {
  padding-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* gap: 15px; */
  align-items: baseline;
}

.arrow {
  width: 32px;
}

.recent_article {
  text-align: left !important;
  /* display: flex;
    width: 70%;
    justify-content: center; */
}

.article {
  color: #785338;
  text-align: left;
}

.recent {
  color: #ffd660;
  text-align: left;
}

.logoImageAbout {
  width: 167px;
  margin-top: 18px;
  margin-left: -13px;
  height: 92px;
}

.aboutContaianer {
  background: url("./assets/image/heathbg.jpg");
  height: auto;
  text-align: left;
  color: #fff;
  padding: 0px 0px;
  margin-bottom: 0px;
  background-size: cover;
}

.contentHeathSpan {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  line-break: anywhere;
}

.contentHeathSpan ul {
  list-style: none;
  font-size: 32px;
  font-weight: 600;
}

ul,
li {
  cursor: pointer;
}

.active_item {
  background-color: #aab6b7 !important;
}

.contentHeathSpan li {
  border-top: 2px solid;
  padding: 16px 0;
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
  text-transform: uppercase;
}

.contentHeathSpan li.overview {
  color: #61422c;
}

.contentHeathSpan .contentHeath_block_right .paragraphtext {
  /* padding-left: 60px; */
  line-break: auto;
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 700;
  line-height: 152%;
}

.video_image {
  width: 100px;
}

.mid_video_image {
  height: auto;
  width: 100%;
}

.video_item {
  max-width: 100%;
  line-break: anywhere;
  border-radius: 12px;
}

.recent_vdieo {
  color: white;
}

.video_text_container {
  text-align: left !important;
}

.newsBg {
  background-color: #c37f4d;
  /* height: 140px; */
  display: flex;
  justify-content: flex-start;
  line-break: anywhere;
  text-align: left;
  align-items: center;
  color: #fff;
  padding: 50px 70px;
}

.searchContainer {
  background-color: #fff;
  color: gray;
  border-radius: 31px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-radius: 4.0625rem;
}

.searchInput {
  padding: 6px 18px;
  border-radius: 50px;
  border-color: #fff;
  border: none;
  outline: 0px;
  box-shadow: 0px 0px 0px transparent !important;
  color: #c37f4d;
  font-family: "Cabin", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  flex: 1;
  height: 100%;
}

.searchInput::placeholder {
  color: #d9d9d9;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.faq-wrap {
  padding: 0 0.75rem;
}

.imagecontainer {
  gap: 0;
  position: relative;
  cursor: pointer;
}

.imagecontainer .faq-title {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 3.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  position: absolute;
  left: 50%;
  bottom: 15px;
  text-align: center;
  width: auto;
  margin-bottom: 0;
  padding: 0;
  transform: translate(-50%);
}

.imagecontainer .common-block {
  position: relative;
  height: 60vh;
  text-align: left;
  font-size: 2.5rem;
  padding: 30px;
  overflow: hidden;
}

.imagecontainer .common-block .sec-content {
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 35px 33px;
  box-sizing: border-box;
  /* height: calc(100% - 130px); */
  height: 100%;
  overflow-y: auto;
  bottom: 0;
  scrollbar-width: 0;
}

.imagecontainer .common-block .sec-content.slide {
  animation: fadeKeyframe 300ms;
}

@keyframes fadeKeyframe {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(-50%);
    opacity: 1;
  }
}

.imagecontainer .common-block .sec-content::-webkit-scrollbar {
  display: none;
}

.imagecontainer .common-block .sec-content p,
.imagecontainer .common-block .sec-content .descrip,
.imagecontainer .common-block .sec-content .descrip2,
.imagecontainer .common-block .sec-content .descrip3 {
  position: relative;
  padding: 0;
  display: block;
  width: 100%;
  left: 0;
}

.imagecontainer .common-block .sec-content .para-txt {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  text-align: justify;
  margin-bottom: 10px;
  margin-top: 0px;
}

.imagecontainer .common-block .sec-content .seemore {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background-color: #ffd660;
  /* width: 200px;
    height: 50px; */
  float: right;
  clear: both;
}

.image {
  margin: 0;
  padding: 0;
}

.nav-menu li a.active {
  color: #000 !important;
  font-weight: bold;
  text-decoration: underline;
}

.active {
  font-weight: bold;
  color: #000;
}

.framevideo {
  width: 100%;
  height: 338px;
  border-radius: 12px;
}

.nav-menu li a.active:hover {
  color: #000 !important;
  font-weight: bold;
  text-decoration: underline;
}

.image1 {
  background-image: url("./assets/image/HPV.jpg");
  background-size: cover;
  height: 500px;
  color: #ffd660;
  font-weight: 700;
  font-size: 32px;
}

.image2 {
  background-image: url("./assets/image/STIs.jpg");
  background-size: cover;
  height: 500px;
  color: #ffd660;
  font-weight: 700;
  font-size: 32px;
}

.image3 {
  background-image: url("./assets/image/sexual.jpg");
  background-size: cover;
  height: 500px;
  color: #ffd660;
  font-weight: 700;
  font-size: 32px;
}

.line {
  height: 50px;
  width: 4px;
  margin: 10px;
}

.imagecontainer p {
  /* text-transform: uppercase; */
  color: #fff;
  position: absolute;
  z-index: 9;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-top: 18%;
}

.descrip {
  color: #fff;
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  padding-top: 18%;
  margin-top: 44px;
  word-wrap: break-word;
  width: 600px;
}

.descrip2 {
  color: #fff;
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 18%;
  margin-top: 44px;
  word-wrap: break-word;
  width: 600px;
  left: 32%;
}

.descrip3 {
  color: #fff;
  position: absolute;
  z-index: 9;
  display: flex;
  align-items: center;
  padding-top: 18%;
  margin-top: 44px;
  word-wrap: break-word;
  width: 600px;
  right: 25px;
}

.aboutContaianer h2 {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 16px;
}

.aboutContaianer h2 .heathspan {
  color: #61422c;
}

.searchbtn {
  background-color: rgba(211, 146, 0);
  border-radius: 50px;
  border: none;
  padding: 10px 28px;
  white-space: nowrap;
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.paddingcls {
  padding: 10px 177px;
  display: flex;
}

.avaproduct {
  text-align: center;
  margin-top: 60px;
}

.dropdown-menu.show {
  display: block;
  background-color: rgba(248, 206, 101, 0.8) !important;
}

.nav-menu li a:hover {
  color: #fff !important;
  background-color: rgba(248, 206, 101, 0.8) !important;
}

.workcontent {
  text-align: center;
  padding: 10px 14px;
  margin-top: 80px;
  margin-bottom: 100px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.workcontent h2 {
  color: #785338;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 50px;
}

.avacolor {
  color: rgba(248, 206, 101, 0.8);
  color: #ffd660;
}

.bgcolordiv {
  background-color: rgba(248, 206, 101, 0.8);
  background-color: #ffd660;
}

.bgcolordiveven {
  background-color: rgb(247 222 158);
  background-color: #ffe7a0;
}

.bgcolordiveven h1 {
  font-size: 5.5rem;
  color: rgba(248, 206, 101, 0.8);
  color: #ffc829;
}

.bgcolordiv h1 {
  font-size: 5.5rem;
  color: rgba(100, 75, 12, 0.8);
  color: #c99c1b;
}

.workcontent .bgcolordiveven,
.workcontent .bgcolordiv {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.workcontent .bgcolordiveven h4,
.workcontent .bgcolordiv h4 {
  color: #61422c;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.15188rem;
}

.workcontent .bgcolordiveven p,
.workcontent .bgcolordiv p {
  color: #61422c;
  text-align: center;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 136%;
  margin-top: 1rem;
}

.customer-rev-wrap.reviewCarousel {
  background: #c37f4d;
}

.customer-rev-wrap.reviewCarousel .paddingcls {
  padding: 54px 0 54px 177px;
  display: block;
}

.seemore {
  background-color: #c37f4d;
  padding: 8px 30px;
  border: none;
  border-radius: 45px;
  color: #fff;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.seemorecontent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.contentHeathSpan li:active {
  border-top: 2px solid black;
  color: #61422c;
}

.main-nav-menu .nav-menu li.has_submenu > a:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-bottom: 1px solid #ffff !important;
  border-left: 1px solid #ffff !important;
  display: inline-block;
  transform: rotate(-45deg);
  margin: 0px 0 3px 12px;
}

.sliderHeaderText {
  text-align: left;
  padding-top: 40px;
}

.sliderParaText {
  text-align: left;
}

.rightarrow {
  background-color: #61422c;
}

.carousel-control-prev {
  left: 0;
  top: 60% !important;
}

.seeDetails {
  background-color: #61422c;
  padding: 20px;
  text-transform: capitalize;
  border-radius: 62px;
  padding: 10px 20px;
  border: none;
}

.carousel-control-next {
  right: 0;
  top: 60% !important;
}

.detailsbtn {
  background-color: #ea409c;
  border-radius: 45px;
  padding: 10px 15px;
  border: none;
}

.detailstext {
  color: #785338;
  text-align: left;
}

.detailsparatext {
  color: #785338;
  text-align: left;
  font-size: 13px;
}

.detailspnametext {
  color: #61422c;
  text-align: right;
  font-size: 11px;
}

.name {
  font-weight: bold;
}

.article_image {
  border-radius: 15px;
  object-fit: cover;
}

.hambarger-menu-header-wrap:hover .text {
  color: #ea409c;
}

.menu-close-btn {
  font-size: 35px;
  display: none;
}

.bg-gray {
  background-color: #f9f9f9;
}

.text-right {
  text-align: right;
}

.bg-yellow {
  background-color: #fee986;
}

.search-form-w {
  max-width: 843px;
  margin: 0 auto;
  transform: translate(0, -25px);
  border: 3px solid #fee986;
  border-radius: 35px;
  background-color: #fff;
  display: flex;
}

.search-form-w .form-control {
  background: transparent;
  border: none;
  border-radius: 999px;
  margin-bottom: 0;
  padding: 10px 20px;
  font-size: 18px;
  font-family: var(--font-family-bold);
}

.search-form-w .btn-search {
  width: 45px;
  height: 45px;
  background: transparent url("./assets/image/btn-search-icon.png");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  margin-right: 10px;
}

.secondary-title {
  font-size: 2.125em;
  line-height: 52px;
  margin-bottom: 16px;
  font-family: var(--font-family-bold);
}

.blog-filter {
  margin-bottom: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.blog-lists {
  margin: 0 auto;
  max-width: 90%;
}

.btn-pill-outline {
  padding: 13px 20px;
  border-radius: 999px;
  border: 3px solid #000000;
  color: #000000;
  font-weight: bold;
  font-size: 0.8em;
}

.post-title {
  margin-bottom: 15px;
  font-size: 1.425em;
  font-family: "Sarabun", Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.post-meta {
  font-weight: bold;
  margin-bottom: 15px;
  font-family: "Sarabun", Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.post-tag-meta {
  display: flex;
  gap: 25px;
  margin-top: 20px;
}

.bg-blue-light {
  background-color: transparent;
  padding: 0px 70px 70px 0;
}

.choose-ava-list {
  background-color: #ff9566;
  max-width: 500px;
  padding: 50px 50px;
  margin-left: -218px;
  box-shadow: 70px 70px 0 0 #bee4e8;
}

.choose-ava-list ul {
  list-style: none;
  padding: 0;
}

.choose-ava-list li {
  font-size: 1.125em;
  color: #000;
  margin-bottom: 24px;
  font-family: var(--font-family-bold);
  background-image: url("./assets/image/icons/tick-icon.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  padding-left: 65px;
  min-height: 55px;
  background-size: 37px 30px;
}

.choose-ava-list .action {
  text-align: center;
}

.choose-ava-list .btn-pink {
  background-color: #fba6d4;
  font-size: 1.55em;
  color: #3c5a99;
  padding: 8px 15px;
  border-radius: 75px;
  min-width: 260px;
  border: 3px solid #3c5a99;
  margin: 0 auto;
  font-family: var(--font-family-bold);
}

.trinary-title-pink {
  color: #df6dab;
  font-size: 1.85em;
  margin-bottom: 40px;
}

.icon-card {
  display: grid;
  grid-template-columns: 75px 1fr;
  gap: 0 15px;
  align-items: flex-start;
  margin-bottom: 15px;
  align-content: flex-start;
}

.icon-card .graphic {
  width: 75px;
  height: 75px;
  background: #d9d9d9;
  border-radius: 999px;
  grid-row: 1/2 span;
  margin-top: 43px;
}

.icon-card .card-title {
  font-size: 1.25em;
  font-family: var(--font-family-bold);
  overflow: auto;
  min-height: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon-card p {
  font-size: 1.0625em;
  font-family: var(--font-family-bold);
  line-height: 1.1;
  margin-bottom: 0;
}

.video-block {
  max-width: 100%;
  margin: 0 auto;
}

.video-block.full {
  max-width: 100%;
  margin: 0 auto;
}

.video-block iframe {
  width: 100%;
  height: 500px;
}

.embed-responsive-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.btn-pink-custom-bg {
  background-image: url('data:image/svg+xml,<svg width="505" height="98" viewBox="0 0 505 98" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M505 48.6765C505 98.4982 440.36 98.0003 252.5 98.0001C64.64 97.9999 0 98.4982 0 48.6765C0 -1.14529 64.9767 0.00480588 252.5 0.00248603C440.023 0.000166193 505 -1.14529 505 48.6765Z" fill="%23DF6DAB"/> </svg>');
  background-repeat: no-repeat;
  background-position: center;
  font-size: 1.875em;
  color: #fff;
  min-width: 500px;
  color: #fff;
  background-size: contain;
  padding: 10px 30px;
}

.bg-orange-light {
  background-color: rgb(255, 237, 175);
}

.list-section {
  padding: 80px 0;
}

.list-section .section-title {
  font-size: 3.0875em;
  color: #bd56bf;
  font-family: var(--font-family-300);
}

.list-section .left-sec {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.list-section .left-sec .section-title {
  max-width: 350px;
}

.custom-skatch-bullets {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-skatch-bullets li {
  font-size: 1.5em;
  color: #bd56bf;
  display: flex;
  gap: 15px;
  margin-bottom: 33px;
  align-items: center;
  line-height: 1.3;
}

.custom-skatch-bullets li .icon {
  flex: 0 0 auto;
}

.custom-skatch-bullets li .icon svg {
  width: 60px;
  height: 60px;
}

.kit-about-lab {
  padding: 80px 0px;
  background-color: #fdfcf3;
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.kit-about-lab:after {
  content: "";
  height: 80%;
  width: 200%;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: rotate(341deg) translate(-44%, 0%);
}

.kit-about-lab .sec-title {
  font-size: 2em;
  font-family: var(--font-family-300);
  color: #18b0bd;
  margin-bottom: 15px;
  font-weight: 600;
}

.kit-about-lab p {
  font-size: 1.125em;
  line-height: 1.3;
  color: #18b0bd;
}

.kit-about-lab .row {
  align-items: center;
}

.kit-about-lab .extra-top-padd {
  padding-top: 7vw;
}

.main-nav-menu {
  display: flex;
}

.main-nav-menu Ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 50px;
}

.main-nav-menu Ul li a {
  color: #5b7fbd;
  font-size: 18px;
}

.main-nav-menu Ul li {
  font-size: 0.9em;
}

.main-nav-menu Ul li a:hover,
.main-nav-menu Ul li:hover > a,
.main-nav-menu Ul li.active a,
.main-nav-menu Ul li a.active {
  color: #ea409c;
}

.header-right-column {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
}

.site-header {
  position: static;
  top: 0;
  left: 0;
}

.site-wrap {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* home blog sec */
.feature-three-blogs {
  padding: 70px 0;
  position: relative;
}

.feature-three-blogs:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  height: 35%;
  background-color: rgba(254, 245, 169, 0.8980392157);
  z-index: -1;
}

.primary-title {
  font-family: "Random Grotesque Standard Bold";
  font-weight: 700;
  font-size: 4.25em;
  color: #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 100px;
}

.primary-title.underline {
  position: relative;
}

.primary-title.underline .line {
  position: absolute;
  line-height: 1;
  top: 60%;
  right: -29px;
  display: block;
}

.primary-title.underline .line svg {
  max-width: 150px;
}

.feature-three-blogs-w .item {
  max-width: 228px;
  line-break: anywhere;
}

.feature-three-blogs-w .item .blog-title {
  font-size: 1.4375em;
}

.feature-three-blogs-w .item:hover a {
}

.feature-three-blogs-w .item * {
}

/* .feature-three-blogs-w .item:hover {
    transform: scale(1.1);
  } */

/* .feature-three-blogs-w .item:hover .graphic {
    transform: rotate(-3deg);
  } */

.feature-three-blogs-w .item a {
  color: #000;
}

.feature-three-blogs-w .graphic {
  margin-bottom: 15px;
}

.section-faq-home {
  min-height: 600px;
  background-color: #d9f0f2;
  padding: 90px 0;
}

.section-faq-home .container {
  position: relative;
}

.section-faq-home .faq-title {
  position: relative;
  z-index: 3;
}

.section-faq-home .faq-q {
  position: absolute;
  z-index: 2;
  transition: all 0.3s;
  transform: scale(0.85);
}

.section-faq-home .faq-q:nth-child(2) {
  top: 41% !important;
  left: 21%;
  z-index: 3;
}

.section-faq-home .faq-q:nth-child(2):hover {
  transform: rotate(10deg) scale(1.1);
}

.section-faq-home .faq-q:nth-child(3) {
  top: 17% !important;
  left: 39.5%;
}

.section-faq-home .faq-q:nth-child(3):hover {
  transform: scale(1.1);
}

.section-faq-home .faq-q:nth-child(4) {
  top: 12% !important;
  left: 62%;
}

.section-faq-home .faq-q:nth-child(4):hover {
  transform: rotate(-10deg) scale(1.1);
}

.section-faq-home .faq-q:nth-child(5) {
  top: 47% !important;
  left: 49%;
}

.section-faq-home .faq-q:nth-child(5):hover {
  transform: rotate(-10deg) scale(1.1);
}

.section-faq-home .faq-q:hover {
  z-index: 99;
}

.section-faq-home .faq-star-1,
.section-faq-home .faq-star-2,
.section-faq-home .faq-star-3 {
  transform: scale(0.85);
}

.section-faq-home .faq-star-1 {
  position: absolute;
  top: 68% !important;
  left: 40%;
  z-index: 3;
}

.section-faq-home .faq-star-2 {
  position: absolute;
  top: 41% !important;
  left: 78%;
  z-index: 3;
}

.section-faq-home .faq-star-3 {
  position: absolute;
  top: 10% !important;
  left: 60%;
  z-index: 1;
}

.single-video-sec {
  padding: 125px 0;
}

.trinary-title {
  color: #f96c2e;
  font-size: 1.625em;
  font-size: 2.2em;
  font-family: "Random Grotesque Spacious Book";
}

.home-recent-blog {
  padding: 90px 0;
}

.home-recent-blog .blogs-w {
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  aspect-ratio: 12/7;
  align-items: center;
}

.home-recent-blog .item {
  max-width: 188px;
}

.blog-title {
  font-size: 1.3em;
  min-height: 80px;
}

.home-recent-blog .item .graphic img {
  width: 188px;
  height: 188px;
  object-fit: cover;
}

.home-recent-blog .item .graphic {
  margin-bottom: 12px;
}

.recent-blog-bg {
  background: url("./assets/image/recent_blog_bg.svg") no-repeat;
  padding: 50px 0;
  background-size: calc(100% - 100px);
  background-position: 50% 0;
}

.home-static-content {
  padding: 0 0 90px 0;
}

.home-static-content .trinary-title {
  margin-bottom: 24px;
}

html .home-static-content .flex-img-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 85px;
  transform: scale(0.8);
}

.cover-card {
  position: relative;
  margin-bottom: 125px;
}

.floted-caption {
  position: absolute;
  top: 0;
  left: 0;
}

.floted-caption .btn-groups {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.floted-caption.pos-bot {
  bottom: 0;
  left: 0;
  top: inherit;
  padding: 25px 45px;
  color: #fff;
}

.floted-caption.pos-bot a {
  color: #fff;
}

.floted-caption .pill {
  border: 3px solid #ffffff;
  border-radius: 35px;
  padding: 13px 25px;
  display: inline-block;
  color: #fff;
}

.page-title {
  font-size: 3em;
  color: #4977c5;
  font-family: "Random Grotesque Standard Bold";
  padding: 40px 0 15px;
}

.cart-container-wrap {
  background-image: url("./assets/image/wave_bg.svg");
  background-size: 100%;
  padding-top: 120px;
  padding-bottom: 70px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}

.cart-container-wrap:after {
  content: "";
  background: #d2e9eb;
  width: 100%;
  height: 70%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

.cart-container-wrap .actions .col-12 {
  display: flex;
  justify-content: center;
}

/* card */
.card-header {
  background: transparent;
  font-size: 2.25em;
  color: var(--color-primary);
  font-family: var(--font-family-500);
  border: none;
  line-height: 1;
  padding: 25px 32px;
}

.card-header.text-color-pink {
  color: var(--color-secondary);
}

/* form */
label {
  font-size: 0.9em;
  color: #5b7fbd;
  margin: 0 0 6px 0;
}

.form-control {
  border: 1px solid #5b7fbd;
  margin-bottom: 15px;
}

.form-select {
  border: 1px solid #5b7fbd;
  margin-bottom: 15px;
}

.card {
  margin-bottom: 10px;
  border: none;
}

.card .card-body {
  padding: 0px 32px 25px;
}

.card.bg-primary {
  --bs-primary-rgb: 73, 119, 197;
}

.card.bg-primary .card-header,
.card.bg-primary label {
  color: #fff;
}

.btn-secondary {
  background-color: var(--color-secondary);
  color: #fff;
  border: none;
  margin: 0 0 15px 0;
  font-size: 1em;
}

.card-payment-method .btn {
  display: block;
  width: 100%;
}

.order-product-card .card-body {
  padding: 10px;
  gap: 10px;
}

.order-product-card .quantity-btns {
  max-width: 150px;
  margin-left: auto;
  background-color: #4977c5;
  padding: 5px;
  border-radius: 3px;
  gap: 4px;
}

.order-product-card .quantity-btns .form-control {
  margin-bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
  border-radius: 3px !important;
  appearance: none;
  min-width: 50px;
  height: 32px;
  border: none;
}

.order-product-card .quantity-btns .input-group-btn .btn {
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
  border: none;
  height: 32px;
  line-height: 1;
}

.order-card-sec .form-control {
  background-color: rgba(255, 255, 255, 0.6);
  border-color: #fff;
}

.order-card-sec .list-group {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  padding-top: 15px;
  padding-bottom: 15px;
}

.order-card-sec .list-group .list-group-item {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  color: #fff;
  border: none;
  font-size: 20px;
}

.order-card-sec .list-group .list-group-item strong {
  font-weight: normal;
}

.order-card-sec .list-group.bold-text {
  border-top: 1px dashed rgba(255, 255, 255, 0.41);
  font-size: 30px;
  font-weight: bold;
}

.order-card-sec .list-group.bold-text strong {
  font-weight: bold;
}

.btn-trinary {
  background-color: #fee986;
  padding: 8px 20px;
  color: rgb(73, 119, 197);
  font-size: 1.125em;
}

.affiliate-page-inner {
  padding: 0px 0;
}

.affiliate-page-inner .content-sec {
  max-width: 410px;
  background: transparent;
  margin: auto 0;
}

.affiliate-page-inner .card-title {
  color: #199ba6;
  font-family: var(--font-family-500);
  font-size: 1.9em;
  text-align: left;
}

.affiliate-page-inner .card-text {
  color: #15666d;
  text-align: left;
  font-size: 1.1em;
  font-family: var(--font-family-400);
}

.affiliate-page-inner .form-label {
  color: #13595f;
  font-family: var(--font-family-bold);
  margin-bottom: 6px;
}

.affiliate-page-inner .form-control {
  border: none;
  border-radius: 10px;
}

.affiliate-page-inner textarea.form-control {
  min-height: 166px;
  resize: none;
}

.affiliate-page-inner .graphic {
  margin: 0;
}

.affiliate-page-inner .btn-primary {
  background: #f5a07b;
  font-size: 1.125em;
  color: #ffffff;
  letter-spacing: 0.01em;
  border: none;
  min-width: 100%;
  margin: 0 auto;
  display: block;
}

.affiliate-page-inner .btn-primary:hover {
  background: #e3845b;
}

.about-page-wrap .top-border {
  background: #d2e9eb;
  height: 47px;
}

.about-page-wrap .static-section .page-title {
  color: #000000;
  font-size: 2.5em;
  line-height: 1;
}

.about-page-wrap .static-section h4.card-title {
  font-size: 1.25em;
  font-family: var(--font-family-500);
  margin: 0 0 20px 0;
}

.about-page-wrap .static-section p {
  font-size: 1em;
  color: #000;
}

.about-page-wrap .static-section .section-heading-primary {
  background-color: #fbc4e2;
  font-size: 1.25em;
  color: #000;
  padding: 5px 3px;
  display: block;
  max-width: 677px;
}

.language-chooser {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  padding: 67px 0 48px;
  display: none;
}

.language-chooser a {
  color: #000000;
  font-size: 1.75em;
  padding: 16px 28px;
  position: relative;
  display: inline-block;
}

.language-chooser a:not(:first-child):before {
  content: "|";
  position: absolute;
  left: -2px;
}

.language-chooser a.active {
  text-decoration: underline;
  background-image: url("./assets/image/icons/language_active.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.about-team {
  padding: 90px 0;
  background: #ffe99d;
}

.about-team .primary-title {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 140px;
}

.team-card {
  background-color: #fdfcf3;
  border-radius: 8px;
  max-width: 248px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 15px;
  min-height: 230px;
}

.team-card .graphic {
  margin-bottom: 15px;
  margin-top: 0;
  transform: translate(0, -75px);
  height: 85px;
}

.team-card .card-title {
  font-size: 1.0625em;
  color: #000;
  margin-bottom: 15px;
}

.team-card p {
  color: #5b7fbd;
}

/* quick-knoledge-sec */
.knoledge-big-banner {
  padding-top: 100px;
}

.knoledge-big-banner h1 img {
  max-width: 750px;
}

.quick-knoledge-sec {
  background-image: url("./assets/image/banner_bg.png");
  background-repeat: no-repeat;
  background-position: 0 100%;
  padding-bottom: 80px;
}

.quick-knoledge-sec .cover-card {
  margin: 0 auto;
}

.page-sub-title {
  font-size: 1.625em;
  margin-bottom: 80px;
}

.knoledge-big-banner .lead {
  font-size: 1.625em;
  font-family: var(--font-family-bold);
  background-image: url("./assets/image/title-border.png");
  background-repeat: no-repeat;
  background-position: 50% 100%;
  padding-bottom: 48px;
  position: relative;
  display: inline-block;
  margin: 0 auto;
}

.knoledge-big-banner .lead:after {
  content: "";
  width: 56px;
  height: 60px;
  background-image: url("./assets/image/title-heart-icon.png");
  position: absolute;
  top: 0;
  right: -70px;
}

/* search-wrap-block */
.search-wrap-block {
  background-color: #fff;
  padding-bottom: 25px;
}

.bg-search-sec {
  padding-top: 40px;
}

.bg-search-sec .primary-title {
  font-style: italic;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-size: 3.75em;
  text-transform: uppercase;
  font-family: var(--font-family-bold);
  text-shadow: none;
}

/* post article */
.post-article {
  margin: 0 0 55px 0;
}

.post-article a {
  color: inherit;
}

.post-article .graphic .image-fluid {
  width: 100%;
  max-height: 378px;
  object-fit: cover;
  border-radius: 10px;
}

.post-pagination {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 100px;
  margin-top: 100px;
}

.post-pagination .page-link {
  border: none;
  font-size: 18px;
  line-height: 137%;
  color: rgba(91, 127, 189, 0.29);
  padding: 5px 15px;
  min-width: 70px;
}

.post-pagination .page-link.active {
  background-color: transparent;
  color: #5b7fbd;
}

.post-pagination .page-link:hover {
  color: #5b7fbd;
  background: transparent;
}

/* secondary-title-light */
.our-kit-sec {
  padding: 80px 0;
}

.secondary-title-light {
  padding-bottom: 35px;
  font-size: 2.2em;
  font-family: var(--font-family-300);
  color: #3c5a99;
}

.kit-card {
  position: relative;
  border: 9px solid transparent;
  border-radius: 12px;
  display: flex;
  margin: 0 auto 50px;
  transition: all 0.3s;
  border-radius: 20px;
}

.kit-card:hover {
  border: 9px solid #fee986;
}

.kit-card img {
  border-radius: 10px;
}

.kit-card .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.site-footer {
  --footer-bg-color: #df6dab;
  --footer-text-color: #fff;
  --footer-font-size: 18px;
  --footer-font-small: 16px;
  --footer-font-lh: 33px;
  --footer-title-size: 24px;
  --footer-title-m: 0 0 33px 0;
  background: var(--footer-bg-color);
  margin-top: auto;
  padding: 74px 0;
}

.site-footer,
.site-footer a {
  color: var(--footer-text-color);
  font-size: var(--footer-font-size);
}

.site-footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.site-footer ul li {
  font-size: var(--footer-font-size);
  margin-bottom: 20px;
}

.site-footer .footer-col-1 {
  max-width: 460px;
  margin-right: auto;
}

.site-footer .footer-title {
  margin: var(--footer-title-m);
  font-size: var(--footer-title-size);
}

.site-footer p {
  margin-bottom: 25px;
}

.site-footer .small {
  font-size: var(--footer-font-small);
}

.site-footer .social-links {
  display: flex;
  gap: 10px 16px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.site-footer .footer-bot-sec {
  text-align: center;
  margin-top: 45px;
}

.footer-inline-links {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 50px;
  justify-content: center;
}

/* new style 6-6-2023 */
.inner-banner {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg-light-blue {
  background: #d2e9eb;
}

.font-2 {
  font-family: "Sarabun", sans-serif;
}

.mt-50 {
  margin-top: 50px;
}

.faq-banner .banner-title {
  font-weight: 800;
  font-size: 96px;
  font-size: clamp(18px, 4.8vw, 96px);
  line-height: 1;
  text-align: center;
  color: #f5a07b;
  text-shadow: -4px -1px 0px rgb(73, 119, 197);
}

.footer-top-sec img {
  max-width: 90%;
}

.search-box {
  display: flex;
  max-width: 100%;
  margin: 38px auto;
  background: #ffffff;
  border: 3px solid #000000;
  border-radius: 35px;
}

.search-box .form-control {
  margin-bottom: 0;
  border: none;
  background: transparent;
  padding: 12px 30px;
}

.search-box .form-control:focus,
.search-box .form-control:active {
  box-shadow: none;
  outline: none;
}

.search-box .btn-search {
  margin-right: 8px;
}

.faq-flex-column {
  margin-bottom: 40px;
}

.faq-flex-column .container .row {
  gap: 20px 0;
}

.faq-flex-column .container .card {
  text-align: center;
  font-size: 1.75em;
  color: #000000;
  padding: 15px;
  max-width: 246px;
  margin: 0 auto;
  box-shadow: 0px 1px 16px -1px #ffffff;
  border-radius: 37px;
}

.faq-flex-column .container .card:hover,
.faq-flex-column .container .card.active {
  box-shadow: 0px 1px 16px -1px #fee986;
}

.faq-flex-column .container .card .graphic {
  margin-bottom: 15px;
}

.faq-flex-column .container .card .card-title {
  font-size: 0.8em;
  font-family: var(--font-family-500);
  color: #000000;
}

.font-1 {
  font-family: var(--font-family-400);
}

.f-36 {
  font-size: 1.75em;
}

.f-32 {
  font-size: 1.625em;
}

.f-20 {
  font-size: 1em;
}

.color-blue-light {
  color: rgb(50, 179, 190);
}

.accordion-header .accordion-button {
  background: #fee986;
  border-radius: 10px 10px 0 0 !important;
  padding: 12px 14px;
  font-weight: 600;
  border: none;
  box-shadow: none;
  font-size: 0.7em;
  line-height: 26px;
  font-family: "Sarabun", sans-serif;
  color: #000000;
}

.accordion-header .accordion-button.collapsed {
  background: #9fced2;
  border-radius: 10px !important;
}

.accordion-header .accordion-button.collapsed:after {
  content: "";
  background: url('data:image/svg+xml,<svg width="47" height="37" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="-1.31134e-07" y1="18.2354" x2="43.871" y2="18.2353" stroke="black" stroke-width="3"/><path d="M25.2031 2L43.8716 18.8016L25.2031 35.6033" stroke="black" stroke-width="3"/></svg>');
  width: 36px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.accordion-header .accordion-button:after {
  content: "";
  background: url('data:image/svg+xml,<svg width="44" height="34" viewBox="0 0 44 34" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="-1.31134e-07" y1="16.2354" x2="43.871" y2="16.2353" stroke="black" stroke-width="3"/></svg>');
  width: 36px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
}

.accordion .accordion-item {
  border: none;
  margin-bottom: 20px;
}

.accordion-collapse {
  background-color: #fee986;
  border-radius: 0 0 10px 10px !important;
  background-repeat: no-repeat;
}

.link-text {
  font-family: "Sarabun", sans-serif;
  font-weight: 800;
  font-size: 1.15em;
  line-height: 26px;
  /* identical to box height */
  text-align: center;
  text-decoration-line: underline;
  color: #000000;
}

.link-text:hover {
  text-decoration: none;
}

.faq-btn-img {
  margin-top: 25px;
}

.faq-btn-img img {
  max-width: 100%;
  width: 100%;
}

.btn-pill-outline:hover {
  color: #32b3be;
  background: #fee986;
  border: 3px solid #32b3be;
  border-radius: 35px;
}

.faq-sec .btn-pill-outline {
  padding: 10px 20px;
}

.faq-sec .blog-filter {
  margin-bottom: 65px;
}

/* about item */
.about-team {
  padding: 90px 0;
  background: #ffe99d;
}

.about-team .primary-title {
  text-align: center;
  font-size: 48px;
  margin-bottom: 140px;
}

.about-team .container {
  max-width: 1170px;
}

.about-team p,
.about-team a {
  color: #000;
}

.about-team .color-blue {
  color: #5b7fbd;
}

.about-team .color-green {
  color: #009d46;
}

.about-team .color-orange {
  color: #f96c2e;
}

.about-team .sub-title {
  font-size: 1.0625em;
  font-family: var(--font-family-bold);
  padding: 0 13px;
}

.team-card {
  padding: 0 20px 15px;
  margin-bottom: 140px;
}

.team-card.bg-1 {
  background: transparent;
  background-image: url("./assets/image/about-item-bg-1.svg");
}

.team-card.bg-2 {
  background: transparent;
  background-image: url("./assets/image/about-item-bg-2.svg");
}

.team-card p.quote {
  margin-bottom: 0;
}

.team-card p.quote:before,
.team-card p.quote:after {
  content: '"';
  display: block;
  font-size: 22px;
  line-height: 1.3;
  display: none;
}

.team-card p.quote:after {
  margin-top: 12px;
}

.quick-contact {
  padding: 135px 15px 115px 15px;
  text-align: center;
  margin: 15px 0;
  background-image: url("./assets/image/about-contact-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.quick-contact .sec-title {
  font-style: normal;
  font-weight: 700;
  font-size: 2.5em;
  text-align: center;
  color: #18b0bd;
}

.quick-contact p {
  font-family: var(--font-family-400);
  font-style: normal;
  font-weight: 400;
  font-size: 1.125em;
  line-height: 115%;
  /* or 28px */
  text-align: center;
  color: #18b0bd;
  margin: 0 0 0 0;
}

.quick-contact .social-icons {
  justify-content: center;
  margin-top: 15px;
}

.social-icons {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 15px 30px;
  padding: 0;
  margin: 0;
}

/* home faq responsive */
.section-faq-home .faq-q .title {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 200px;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: clamp(7px, 2vw, 22px);
}

.section-faq-home img {
  max-width: 100%;
}

.section-faq-home .faq-title {
  width: 36.5%;
}

.section-faq-home .faq-q:nth-child(3) {
  max-width: 21%;
}

.section-faq-home .faq-q:nth-child(4) {
  width: 22%;
}

.section-faq-home .faq-q:nth-child(2) {
  width: 26%;
}

.section-faq-home .faq-q:nth-child(5) {
  width: 25%;
}

.section-faq-home .faq-star-2 {
  width: 25%;
}

.section-faq-home .faq-star-1 {
  width: 15%;
}

.section-faq-home {
  min-height: clamp(100px, 58vw, 600px);
  overflow: hidden;
}

.recent-blog-bg {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

img {
  max-width: 100%;
}

.blog-page .btn.btn-pill-outline {
  border-color: #d2d2d2;
}

.blog-page .btn.btn-pill-outline:hover,
.blog-page .btn.btn-pill-outline.active {
  background: #4977c5 !important;
  border-color: #4977c5 !important;
  color: #fff;
}

.carousel-indicators [data-bs-target] {
  width: 19px;
  height: 3px;
  margin: 0 10px;
}

.carousel-indicators .active {
  width: 120px;
}

.circle-wrap-highlight-w {
  position: relative;
  z-index: 1;
}

.circle-wrap-highlight {
  position: static;
  z-index: 1;
}

.circle-wrap-highlight .circle-svg {
  position: absolute;
  z-index: -1;
  top: -40px;
  right: -50px;
}

.circle-wrap-highlight.circle_first .circle-svg {
  right: inherit;
  left: -90px;
}

.main-nav-menu .nav-menu li.has_submenu {
  position: relative;
}

.main-nav-menu .nav-menu li.has_submenu ul {
  position: absolute;
  top: 98%;
  left: 0;
  min-width: 180px;
  background-color: #fff;
  flex-direction: column;
  gap: 0px;
  opacity: 0;
  visibility: hidden;
  margin-top: 15px;
  transition: all 0.3s;
  z-index: 9991;
}

.main-nav-menu .nav-menu li.has_submenu ul li a {
  display: block;
  padding: 10px 15px;
}

.main-nav-menu .nav-menu li.has_submenu ul li {
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.main-nav-menu .nav-menu li.has_submenu > a:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-bottom: 1px solid #5b7fbd;
  border-left: 1px solid #5b7fbd;
  display: inline-block;
  transform: rotate(-45deg);
  margin: 0px 0 3px 12px;
}

.main-nav-menu .nav-menu li.has_submenu:hover > a:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-bottom: 1px solid #ea409c;
  border-left: 1px solid #ea409c;
  display: inline-block;
  transform: rotate(-45deg);
  margin: 0px 0 3px 12px;
}

.main-nav-menu .nav-menu li.has_submenu:hover > ul {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.card.order-product-card .graphic {
  flex: 0 0 100px;
}

/* Sushanta start */
@import url("https://fonts.googleapis.com/css2?family=Sarabun:wght@400;500;600;700;800&display=swap");

img {
  width: auto;
  max-width: 100%;
}

.inner-small-banner img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.kit_hpv_des_top img {
  max-width: 100%;
  width: 100%;
}

.kit_hpv_des_mid {
  background: #fdd6eb;
}

.kit_hpv_des_mid.bg2update {
  background: #d2e9eb;
}

.bg2update .kit_hpv_des_info h2 {
  text-align: left;
  color: #31b4bf;
}

.bg2update .kit_hpv_des_info p {
  text-align: left;
  color: #000000;
}

.inner-small-banner.innerbanner {
  position: relative;
}

.innerbanner .banner_text {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 3.7em;
  line-height: 1.3;
  text-align: center;
  color: #fff1be;
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, 42%);
  text-shadow: -6px 3px 0px #5b7fbd;
}

.innerbanner .banner_text span {
  display: block;
}

.inner_head_des h2 {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 1.875em;
  line-height: 1.3;
  text-align: center;
  color: #ffba9b;
}

.inner_head_des p {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 1.3;
  text-align: center;
  color: #000000;
}

.avaKit_icon1 {
  position: absolute;
  right: 2%;
  top: -113px;
  z-index: 10;
  width: 100px;
}

.newv_text {
  position: absolute;
  left: 6%;
  top: -3%;
  z-index: 10;
  width: 60px;
}

.kit_hpv_pic_opt {
  flex: 0 20%;
}

.kit_hpv_pic_opt img {
  border-radius: 0;
}

.kit_add_banner,
.avaKit_opt_text {
  width: 90%;
  margin: 0px auto;
}

.def_btn_opt {
  padding: 20px 0px;
}

.def_btn_opt a {
  font-family: "Random Grotesque Slim Black";
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 145%;
  /* identical to box height, or 29px */
  letter-spacing: 0.01em;
  color: #ffffff;
  background: #ffba9b;
  border-radius: 51px;
  display: block;
  max-width: 200px;
  text-align: center;
  padding: 8px 0px;
  margin: 0px auto;
}

.kit_hpv_des_info h2 {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 1.875em;
  line-height: 1.3;
  /* identical to box height */

  color: #fff;
  text-align: center;
}

.kit_hpv_des_info p {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  /* font-size: 32px;
    line-height: 43px; */
  font-size: 1.25em;
  line-height: 40px;
  text-align: center;
  color: #000000;
}

.kit_hpv_des_info {
  position: relative;
  margin-top: -100px;
}

.kit_hpv_des_info h2 {
  position: relative;
}

.kit_hpv_des_info h2 span {
  position: absolute;
  left: -38px;
  bottom: 37px;
  width: 93px;
}

.qus1_pic {
  position: absolute;
  right: 0;
  bottom: -119px;
}

.kit_hpv_des_add {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 64px 0;
  position: relative;
  z-index: 100;
}

.kit_add_banner_opt h2 {
  font-family: "Random Grotesque Spacious Black";
  font-style: italic;
  font-weight: 900;
  font-size: 1.75em;
  line-height: 145%;
  color: #3c5a99;
  text-align: center;
}

.kit_add_banner {
  text-align: center;
  padding: 40px 0px 220px;
}

.site-wrap {
  overflow: hidden;
}

.avaKit_opt {
  background: #d2e9eb;
  padding: 100px 0;
  text-align: center;
  position: relative;
}

.avaKit_opt2 {
  background: #fff5d1 !important;
}

.avaKit_opt:after {
  position: absolute;
  left: 0px;
  top: -102px;
  width: 108%;
  height: 100%;
  content: "";
  border-top: 25px solid #fee986;
  transform: rotate(-6.56deg);
  background: #d1ebef;
}

.avaKit_opt2:after {
  border-top: 25px solid #fff5d1;
  transform: rotate(-6.56deg);
  background: #fff5d1;
}

.avaKit_opt_banner h2 {
  font-family: "Random Grotesque Slim Book";
  font-style: normal;
  font-weight: 400;
  font-size: 2.5em;
  line-height: 145%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #5b7fbd;
}

.avaKit_opt2 .avaKit_opt_banner h2 {
  color: #f96c2e;
}

.avaKit_opt_banner {
  position: relative;
  z-index: 10;
  margin-top: -128px;
}

.order_ket_opt {
  position: relative;
  z-index: 3;
}

.howitowrk_wrap {
  position: relative;
  z-index: 3;
}

.order_ket_opt a {
  background: #5b7fbd;
  font-family: "Random Grotesque Slim Black";
  font-style: italic;
  font-weight: 900;
  font-size: 1.75em;
  line-height: 145%;
  text-align: center;
  letter-spacing: 0.01em;
  color: #ffffff;
  display: block;
}

.order_ket_opt2 a {
  background: #ffba9b;
}

.howitowrk_info {
  padding: 80px 0;
  display: flex;
  /* justify-content: center; */
  max-width: 1000px;
  margin: 0 auto;
}

.howitowrk_info_left {
  flex: 0 38%;
  margin-left: 0;
  text-align: end;
}

.howitowrk_info_right {
  flex: 1;
  margin-left: 90px;
}

.howitowrk_info_right ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.howitowrk_wrap {
  background: #fdfdfd;
}

.howitowrk_info_right ul li {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2em;
  line-height: 100%;
  position: relative;
  color: #32b3be;
  margin-bottom: 20px;

  color: #32b3be;
  padding-left: 15px;
}

.howitowrk_info_right ul li strong {
  display: block;
  font-family: "Random Grotesque Standard Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 1.875em;
  line-height: 100%;
  letter-spacing: 0.045em;
  color: #32b3be;
}

.howitowrk_info_right ul li span {
  display: block;
  font-family: "Random Grotesque Standard Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0.045em;
  color: #32b3be;
  background: url("./assets/image/vector_circle.png") no-repeat center center;
  position: absolute;
  left: -55px;
  top: 0px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-size: 90%;
}

.positive_info {
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
}

.positive_info h2 {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 2.375em;
  line-height: 1.2;
  color: #df6dab;
  padding-bottom: 10px;
}

.positive_info p {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 1.35em;
  line-height: 1.3;
  text-align: center;
  color: #000000;
  padding-bottom: 30px;
}

.def_btnopt2 {
  margin: 25px auto 80px;
  text-align: center;
}

a.learn_more_btn {
  border: 2px solid #df6dab;
  border-radius: 37px;
  font-family: "Sarabun", sans-serif;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 145%;
  letter-spacing: 0.01em;
  color: #df6dab;
  width: 200px;
  display: block;
  text-align: center;
  padding: 7px 0px;
  margin: 0px auto;
}

.howitowrk_info_left h2 {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 4em;
  line-height: 1.3;
  color: #32b3be;
}

.howitowrk_leftimg img {
  max-width: 200px;
}

.howitowrk_info_left h2 span {
  display: block;
}

.positive_info {
  position: relative;
}

.positive_info span {
  position: absolute;
  right: 13%;
  width: 65px;
  bottom: 32px;
}

.sep1 {
  background: #d2e9eb;
  height: 10px;
  margin: 10px 0;
}

.bandcamp_des {
  padding: 20px 0px 25px;
}

.bandcamp_des ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.bandcamp_des ul li {
  position: relative;
  margin-right: 8px;
  padding-right: 8px;
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  /* identical to box height */
  color: #000000;
}

.bandcamp_des ul li:last-child:after {
  display: none;
}

.bandcamp_des ul li a {
  font-family: "Sarabun", sans-serif;
  color: #3c5a99;
}

.selected {
  font-weight: 700 !important;
  color: #000000;
}

.bandcamp_des ul li:after {
  content: ">";
  right: -6px;
  top: 0px;
  position: absolute;
}

.addpaddint_top {
  padding-top: 80px;
}

.avaKit_opt {
  background: #d2e9eb;
  padding: 100px 0;
  text-align: center;
  position: relative;
}

.hpv_continfo {
  background: #d2e9eb;
  padding: 80px 0;
}

.inner_head_des2 h2 {
  font-family: "Random Grotesque Slim Book";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 145%;
  text-align: left;
  letter-spacing: 0.01em;
  color: #5b7fbd;
}

.inner_cont_wrap {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 137%;

  color: #000000;
}

.inner_cont_wrap h3 {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 137%;
  color: #000000;
}

.inner_cont_wrap li {
  padding-bottom: 4px;
}

.prod_des {
  display: flex;
  gap: 30px;
  padding-bottom: 80px;
}

.prod_add_info {
  position: relative;
  flex: 0 48%;
}

.prod_add_slider img {
  border-radius: 10px;
}

.prod_add_content {
  position: relative;
  flex: 0 48%;
}

.prod_add_content h2 {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 54px;
  line-height: 64px;
  color: #000000;
}

.prod_add_content h2 span {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 38px;
  color: #000000;
  display: block;
}

.price_info {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.price_text {
  font-family: "Random Grotesque Standard Semibold";
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: initial;
  color: #000000;
}

.prod_cont_des h3 {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 137%;
  color: #5b5b5b;
}

.prod_cont_des {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 137%;
  color: #000000;
}

.prod_dtn_option {
  display: flex;
  align-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.prod_inc {
  display: flex;
}

.prod_inc {
  display: flex;
  border: 1px solid #dddddd;
  border-radius: 8px;
  align-items: center;
}

.prod_inc span {
  width: 28px;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
}

.prod_inc_inp input {
  width: 40px;
  border: 0px;
  padding: 6px;
  text-align: center;
  outline: 0px !important;
  box-shadow: 0px 0px 0px transparent;
}

.addtocart_but {
  background: #fff;
  border: 3px solid #5b7fbd;
  border-radius: 8px;
  color: #5b7fbd;
  font-size: 18px;
  padding: 7px 0px;
  width: 190px;
  font-weight: 600;
  text-align: center;
}

.buynow_btn {
  background: #5b7fbd;
  border: 3px solid #5b7fbd;
  border-radius: 8px;
  color: #fff;
  font-size: 18px;
  padding: 7px 0px;
  font-weight: 600;
  text-align: center;
  width: 190px;
}

.prod_cont_des {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.prod_cont_des p {
  padding: 0px;
  margin: 0px;
}

.modf_prod {
  padding-top: 20px;
}

/* .modf_prod .prod_view_des {} */

.modf_prod .kit_hpv_pic_opt .prod_add {
  text-align: center;
  position: relative;
  top: 0px;
  transition: transform 0.2s;
  cursor: pointer;
}

.modf_prod .kit_hpv_pic_opt:hover .prod_add {
  transform: scale(1.2);
  position: relative;
  top: 23px;
}

.modf_prod .kit_hpv_pic_opt.activeprod .prod_add {
  transform: scale(1.2);
  position: relative;
  top: 23px;
}

.prod_add h3 {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 100%;
  color: #ea409c;
  padding-bottom: 20px;
}

.activeprod_des {
  display: block !important;
  transition: transform 0.3s;
}

.prod_view_text_block {
  position: relative;
  background: url("./assets/image/arrow_info_add.png") no-repeat left top;

  padding: 108px 100px 75px;
  background-size: 100% 100%;
  margin-bottom: 20px;
  transition: transform 0.3s;
  display: none;
}

.prod_view_text_block.opt1 {
  background: url("./assets/image/arrow_info_add.png") no-repeat left top;
  background-size: 100% 100%;
  transition: transform 0.3s;
}

.prod_view_text_block.opt2 {
  background: url("./assets/image/arrow_info_add2.png") no-repeat left top;
  background-size: 100% 100%;
  transition: transform 0.3s;
}

.prod_view_text_block.opt3 {
  background: url("./assets/image/arrow_info_add3.png") no-repeat left top;
  background-size: 100% 100%;
  transition: transform 0.3s;
}

.prod_view_text_block.opt4 {
  background: url("./assets/image/arrow_info_add4.png") no-repeat left top;
  background-size: 100% 100%;
  transition: transform 0.3s;
}

.prod_view_text_block ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.prod_view_text_block li {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 100%;
  color: #9a4a9b;
  padding: 8px;
  flex: 0 50%;
}

.prod_view_text_block li a {
  color: #9a4a9b;
  text-decoration: none;
}

.kit_add_banner_cont {
  display: flex;
  position: relative;
  padding: 30px 0 271px;
}

.add_opt {
  flex: 0 48%;
  position: relative;
}

.add_opt_text {
  flex: 0 48%;
  position: relative;
}

.add_pic1 img {
  width: 90%;
}

.add_pic2 {
  position: absolute;
  right: -87px;
  top: 170px;
  width: 50%;
}

.add_opt_text h2 span {
  position: absolute;
  left: 3px;
  top: 0px;
}

.add_opt_text h2 {
  text-align: left;
  position: relative;
  padding-left: 78px;
  font-family: "Sarabun", sans-serif;
  padding-bottom: 44px;
}

.star_icon2 {
  position: absolute;
  left: 56px;
  width: 35px;
}

.add_opt_text_des ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.add_opt_text_des ul li {
  font-family: "Random Grotesque Standard Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125em;
  line-height: 100%;
  letter-spacing: 0.045em;
  color: #5b7fbd;
  padding: 20px 0px 16px 50px;
  margin-left: 114px;
  background: url("./assets/image/checkopt.png") no-repeat left 14px;
  background-size: 35px;
}

.stlsoption .inner_head_des h2 {
  color: #31b4bf;
}

.stlsoption .def_btn_opt a {
  color: #ffffff;
  background: #31b4bf;
}

.innerbanner2 .banner_text {
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: 3.7em;
  line-height: 1.2;
}

.blog_content_outer {
  background: #eef7f8;
}

.blog_content_outer {
  background: #eef7f8;
  padding: 50px;
}

.inner_maincont {
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 50px;
}

.inner_head_info h2 {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 20px;
  line-height: 137%;
  /* or 27px */
  text-align: justify;
  color: #000000;
  text-align: center;
  padding-bottom: 15px;
}

.caption_info h3 {
  font-family: "Random Grotesque Spacious Book";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 137%;
  text-align: center;
  color: #000000;
  padding: 10px 0 0;
}

.caption_info p {
  font-family: "Random Grotesque Standard Book";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 137%;
  /* identical to box height, or 44px */
  color: #000000;
  text-align: center;
}

.inner_cont_des_view p {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 145%;
  /* or 35px */

  color: #000000;
}

.addinfo3 img {
  /* 2648 */
  /* width: 255px;
      height: 300px;
      margin-left: auto;
      margin-right: auto; */
  width: 100%;
}

.blog-middle-img {
  text-align: center;
  display: flex;
  justify-content: center;
}

.blog-middle-img img {
  max-width: 400px;
}

.inner_block_info2 {
  display: flex;
  gap: 30px;
}

.innerblog_add img {
  border-radius: 9px;
  width: 100%;
}

.innerblog_des2 {
  flex: 1;
}

.innerblog_add {
  flex: 0 29%;
}

.inner_subblock {
  padding: 50px;
  background: #d2e9eb;
  border-radius: 0px 0px 10px 10px;
}

.inner_subblock h2 {
  font-family: "Random Grotesque Slim Book";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 43px;
  text-align: center;
  padding-bottom: 20px;

  color: #000000;
}

.inner_subblock_des {
  display: flex;
  gap: 30px;
}

.inner_subblock_des img {
  width: 100%;
  border-radius: 6px;
}

.blog_item_pic {
  margin-bottom: 11px;
}

.blog_item p {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 18px;
  line-height: 137%;
  /* or 27px */
  /* text-align: justify; */

  /* black */
  color: #000000;
}

.blog_item p a {
  color: #000000;
}

.profile_add {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.pref_pic img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-right: 10px;
}

.pref_pic_text h4 {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #000000;
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.pref_pic_text p {
  font-family: "Sarabun", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 145%;
  text-align: left;

  color: #818181;
}

/* 5-7-2023 code for social */
.social-media-add-w {
  background: #fff;
  padding: 5px;
  border-radius: 9px;
  margin-bottom: 15px;
}

.social-media-add-w button {
  border: none;
  background: transparent;
}

.plus_icon {
  display: inline-block;
  vertical-align: top;
}

span.plus_icon:before {
  content: "+";
  font-weight: bold;
  font-size: 32px;
  font-family: Arial;
  line-height: 15px;
  color: #13595f;
  display: block;
  margin-top: 4px;
}

/* Sushanta end */

.home-static-content .flex-img-card {
  transform: scale(0.85);
}

.addtocart_but[disabled] {
  opacity: 0.3;
  filter: grayscale(1);
}

input#quantity {
  text-align: center;
  appearance: none;
  -webkit-appearance: none;
  display: block;
}

input#quantity::-webkit-inner-spin-button,
input#quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* fixed */
p.blog-quick-desc {
  height: 140px;
  overflow-y: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.col-5.brand.logobg {
  width: auto !important;
}

.col-7.header-right-column {
  flex: 1;
  gap: 0px;
  width: auto !important;
}

@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");

body,
a {
  font-family: "Cabin", sans-serif;
}

.customContainer {
  max-width: 1920px;
  font-family: "Cabin", sans-serif;
}

.logobg {
  text-align: left;
}

.white {
  color: #fff;
}

.logobg img {
  width: 178px;
  /* margin-top: 7px; */
  /* height: 45px; */
  /* margin-top: 16px; */
}

.banner_option .owl-prev::after {
  background: none !important;
  color: inherit !important;
  border: none !important;
  font: inherit !important;
}

.banner_option .owl-prev::after {
  content: "";
  background: url(./assets/image/left.png) no-repeat center !important;
  padding: 100px !important;
}

.banner_option .owl-next::after {
  background: none !important;
  color: inherit !important;
  border: none !important;
  font: inherit !important;
}

.banner_option .owl-next::after {
  content: "";
  background: url(./assets/image/right.png) no-repeat center !important;
  padding: 100px !important;
}

.newBgcolor {
  background-color: #ffc228;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  /* padding: 15px 0; */
}

.cartBg img {
  width: 21px;
  margin-top: -9px;
}

.srchicn img {
  width: 17px;
}

footer {
  background-color: rgba(248, 206, 101, 1);
}

.footerTop .paddingcls {
  padding: 10px 20px;
}

.footerTop {
  text-align: left;
  padding-top: 30px;
}

.footerTop h5 {
  text-transform: uppercase;
  font-size: 22px;
  color: #61422c;
  padding-bottom: 15px;
}

.footerTop h6 {
  text-transform: uppercase;
  font-size: 22px;
  color: #61422c;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 10px;
}

.footerTop a {
  display: block;
  margin-bottom: 5px;
  font-size: 15px;
  color: #61422c;
}

.footerBottom {
  border-top: 2px solid #61422c;
  padding: 15px 0;
}

.footerLft {
  text-align: left;
}

.footerRgt {
  text-align: right;
}

.footerBottom a {
  color: #61422c;
  font-size: 16px;
  font-weight: 400;
}

.dt {
  color: #61422c;
  display: inline-block;
  font-size: 26px;
  line-height: 25px;
  position: relative;
  top: -2px;
  padding-right: 10px;
}

.footerRgt a {
  font-size: 22px;
  line-height: 22px;
  margin-left: 10px;
  background: #61422c;
  border-radius: 100px;
  padding: 5px;
  width: 33px;
  height: 33px;
  display: inline-block;
  text-align: center;
  color: #fff;
}

.footerRgt span {
  font-size: 18px;
}

.nav-menu {
  display: flex;
  margin: 0;
}

.nav-menu li {
  margin: 0 20px;
  list-style-type: none;
  flex: none;
}

.nav-menu li .nav-link {
  font-size: 18px;
  line-height: 30px;
  color: #61422c;
  text-transform: uppercase;
}

.nav-menu li li a {
  color: #61422c;
  font-size: 15px;
}

.nav-menu li li a:hover {
  background-color: #61422c;
  color: #fff;
}

.nav-menu li li {
  margin: 0;
}

.qtty {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #785338;
  padding-bottom: 20px;
}

.qtybtnbg {
  border: 1px solid #785338;
  display: flex;
  border-radius: 50px;
  overflow: hidden;
  align-items: center;
  margin-left: 50px;
}

.qtybtnbg button {
  border: none;
  outline: none;
  font-size: 30px;
  color: #785338;
  background-color: #fff;
  padding: 0px 15px;
}

.qtybtn1 {
  border-right: 1px solid #785338 !important;
}

.qtybtn2 {
  border-left: 1px solid #785338 !important;
}

.countspan {
  padding: 0 20px;
  font-size: 15px;
  color: #785338;
  width: 80px;
  text-align: center;
}

h3 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  color: #61422c;
}

h4 {
  font-size: 25px;
  line-height: 33px;
  font-weight: 600;
  color: #61422c;
}

h5 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #61422c;
}

h3 span {
  color: #6fbba0;
}

p {
  font-size: 18px;
  font-weight: 400;
  color: #785338;
}

.commonGrey {
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 20px;
}

.customContainer {
  text-align: left;
}

.commonButton {
  border-radius: 50px;
  padding: 8px 20px;
  border: none;
  outline: none;
  color: #fff;
  background-color: #c37f4d;
  font-size: 16px;
}

.commonButton:hover {
  background-color: #000;
}

.form-control {
  height: 55px;
  border: 1px solid #61422c;
  font-size: 15px;
}

.form-select {
  height: 55px;
  border: 1px solid #61422c;
  font-size: 15px;
}

.bdr {
  width: 100%;
  height: 0;
  border-bottom: 1px solid #785338;
  font-size: 0;
}

.revw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 18px;
  padding-bottom: 25px;
}

.revw h4 {
  color: #785338;
  font-size: 25px;
  line-height: 28px;
  font-weight: 600;
  margin: 0;
}

.revw span {
  color: #785338;
}

.navbar-toggler {
  border: none;
  box-shadow: none !important;
}

.langDrop {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 2rem;
  line-height: normal;
  color: #fff;
  text-transform: uppercase;
  padding: 0;
}

.langDrop option {
  color: #61422c;
}

/* new css */
.header-right-column .navbar {
  padding: 0;
}

.header-right-column .nav-menu .nav-item {
  margin: 0;
}

.header-right-column .nav-menu .nav-item .nav-link {
  padding: 19px 15px;
  position: relative;
  transition: 0.2s;
  /* font-size: 2rem; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header-right-column .nav-menu .nav-item .nav-link.active {
  background: rgba(248, 206, 101, 0.8);
  text-decoration: none;
}

.header-right-column .nav-menu .nav-item .nav-link:hover::before,
.header-right-column .nav-menu .nav-item .nav-link.active::before {
  content: "";
  width: 100%;
  height: 3px;
  background: #000;
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -3px;
  border: none;
  margin: 0;
}

.header-right-column .nav-menu .nav-item .nav-link:hover {
  color: #000 !important;
}

.header-right-column .nav-menu .nav-item .dropdown-item.active {
  color: #000;
  background: #d3b05b;
}

.newBgcolor .navbar {
  padding: 0;
}

.newBgcolor .navbar .navbar-brand {
  max-width: 12rem;
  padding: 0;
}

.item.slideropt {
  position: relative;
}

.slideropt {
  /* height: 100vh; */
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.banner_add {
  /* height: 100vh; */

  height: calc(100vh - 81px) !important;

  width: 100%;
}

.banner_add img {
  /* height: 100vh; */
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.banner_add .cardcontainer {
  display: flex;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 157px;
  word-break: break-all;
  background: rgba(0, 0, 0, 0.19);
  box-sizing: border-box;
  height: 100%;
}

.banner_option .owl-theme .owl-nav {
  margin: 0;
}

.banner_option .owl-theme .owl-dots .owl-dot {
  width: 2.75rem;
  height: 0.525rem;
  border-radius: 0.625rem;
  margin-left: 0.5rem;
}

.banner_option .owl-theme .owl-dots .owl-dot.active span,
.feature-blog .owl-theme .owl-dots .owl-dot.active span {
  /* width: 80px !important; */
  color: rgba(248, 206, 101, 0.8) !important;
  background-color: rgba(248, 206, 101, 0.8) !important;
}

/* .banner_option .owl-theme .owl-dots .owl-dot span,
  .feature-blog .owl-theme .owl-dots .owl-dot span {
    width: 25px !important;
    height: 4px !important;
    border-radius: 0px !important;
  } */
.banner_option .owl-theme .owl-dots {
  bottom: 1.25rem;
}

.banner_option .owl-theme .owl-dots .owl-dot span {
  width: 100%;
  margin: inherit;
  height: 8px;
}

.banner_option .owl-dots,
.feature-blog .owl-dots,
.feature-blog .owl-dots,
.feature-blog .owl-dots {
  position: absolute;
  left: 0px;
  z-index: 100;
  bottom: 13px;
  width: 100%;
}

button.owl-prev {
  top: 50%;
  position: absolute;
  left: 4%;
  background: transparent !important;
  display: block;
}

button.owl-prev:hover,
button.owl-next:hover {
  background: transparent !important;
}

button.owl-prev span,
button.owl-next span {
  width: 50px;
  height: 50px;
  /* background: url(./assets/image/left.png) no-repeat center; */
  display: block;
  background-size: cover;
  font-size: 0px;
  background: #ddd;
  position: relative;
  border-radius: 50%;
}

button.owl-prev::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-right: 20px solid #61422c;
  border-bottom: 14px solid transparent;
  display: block;
  position: absolute;
  top: 48%;
  left: 48%;
  transform: translate(-52%, -48%);
}

button.owl-next::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 14px solid transparent;
  border-left: 20px solid #61422c;
  border-bottom: 14px solid transparent;
  display: block;
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-48%, -50%);
}

button.owl-prev:hover span,
button.owl-next:hover span {
  background-color: #ffd660;
}

button.owl-prev:hover::after {
  border-right-color: #fff;
}

button.owl-next:hover::after {
  border-left-color: #fff;
}

button.owl-next {
  top: 50%;
  position: absolute;
  right: 4%;
  /* background: transparent !important; */
  display: block;
}

button.owl-next span {
  /* width: 40px;
    height: 40px;
    background: url(./assets/image/right.png) no-repeat center;
    display: block;
    background-size: cover;
    font-size: 0px; */
  width: 50px;
  height: 50px;
  /* background: url(./assets/image/right.png) no-repeat center; */
  display: block;
  background-size: cover;
  font-size: 0px;
  background: #ddd;
  position: relative;
  border-radius: 50%;
}

.banner_add .sliderHeaderText {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 96px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
}

.banner_add .sliderParaText {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
  margin-bottom: 60px;
}

@media screen and (max-width: 992px) {
  .banner_add .sliderHeaderText {
    font-size: 3.5rem;
  }

  .banner_add .sliderParaText {
    font-size: 1.5rem;
    margin-bottom: 20px;
    line-clamp: 2;
  }
}

.banner_add .seeDetails {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background: #61422c;
  text-align: center;
  padding: 10px 21px;
  float: left;
}

.feature-blog .owl-dots,
.video_adddetails .owl-dots {
  display: none;
}

.feature-blog button.owl-prev span,
.video_adddetails button.owl-prev span {
  width: 30px;
  height: 30px;
  background: url(./assets/image/left.png) no-repeat center;
  display: block;
  background-size: cover;
  font-size: 0px;
}

.feature-blog button.owl-next span,
.video_adddetails button.owl-next span {
  width: 30px;
  height: 30px;
  background: url(./assets/image/right.png) no-repeat center;
  display: block;
  background-size: cover;
  font-size: 0px;
}

.recent_article {
  width: 100%;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2.5rem;
}

.feature-three-blogs-w.feature-blog,
.video_add {
  max-width: 1000px;
  margin: 0px auto;
  max-width: 80%;
  position: relative;
}

.video_adddetails {
  /* max-width: 1000px; */
  max-width: 80%;
  margin: 0px auto;
  position: relative;
}

.feature-blog button.owl-prev::after,
.feature-blog button.owl-next::after {
  display: none;
}

.feature-blog button.owl-next,
.video_adddetails button.owl-next {
  right: -6%;
  top: 40%;
}

.feature-blog button.owl-prev,
.video_adddetails button.owl-prev {
  left: -6%;
  top: 40%;
}

.feature-three-blogs-w .item {
  max-width: 100% !important;
}

.feature-three-blogs-w.feature-blog {
  padding: 10px 0px 25px;
}

.feature-three-blogs-w .detailsbtn {
  border-radius: 1.875rem;
  background: #df6dab;
  color: #fff;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px 16px;
}

.feature-three-blogs-w .detailsbtn.blue {
  background: #57add7;
}

.feature-three-blogs-w .graphic {
  margin-bottom: 20px;
}

.feature-three-blogs-w .graphic img {
  object-fit: covers;
  height: 23vw !important;
}

.feature-three-blogs-w .detailstext {
  color: #785338;
  font-family: "Sarabun", sans-serif;
  font-size: 1.1rem;
  text-align: center;
  padding: 0 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 137%;
  text-transform: uppercase;
  margin-top: 1.375rem;
  margin-bottom: 2.75rem;
}

.feature-three-blogs-w .detailspnametext {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.feature-three-blogs-w .detailsparatext {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.video_adddetails button.owl-prev::after,
.video_adddetails button.owl-next::after {
  display: none;
}

.video_adddetails .video_text_container {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 2.5rem;
}

.video_adddetails .video_text_container .article {
  color: #785338;
}

.video_adddetails .detailstext {
  color: #785338;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 0.5rem 0 0 0;
}

.defpadding {
  padding: 80px 0px 40px;
}

.marg_top {
  position: relative;
  margin-top: -20px;
}

.contentHeath_block {
  display: flex;
  justify-content: space-between;
}

.contentHeath_block_left {
  flex: 0 49%;
}

.contentHeath_block_right {
  flex: 0 49%;
}

.contentHeath_block_left ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.graphic img {
  width: 100% !important;
  height: auto !important;
}

.newsBg_des {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* max-width: 900px;
    max-width: 65%; */
  margin: 0px auto;
}

.newsBg_des_left {
  flex: 0 49%;
}

.newsBg_des_right {
  flex: 0 49%;
}

.mob_slider {
  display: none !important;
}

.newsBg_des_left h2 {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0;
}

.body-text p {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.15rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: 145%;
}

@media only screen and (max-width: 992px) {
  .popular-section .screen-image {
    border-radius: 12px;
  }

  .feature-three-blogs-w .graphic img {
    object-fit: cover;
    height: 54vw !important;
  }

  .recent_article {
    text-align: center !important;
  }

  .video_text_container {
    text-align: center !important;
  }

  .newsBg {
    padding: 24px;
  }

  .customContainer {
    text-align: center;
  }

  .newsBg_des {
    justify-content: center;
  }

  .newsBg_des_left h2 {
    font-size: 2rem;
    white-space: nowrap;
    padding: 24px 5px;
  }

  .searchInput {
    width: 14rem;
  }

  .imagecontainer .common-block {
    height: 377px;
    display: flex;
  }

  .imagecontainer .common-block .sec-content {
    padding: 0 33px 33px;
  }

  .imagecontainer .faq-title {
    font-size: 2.7rem;
    text-align: center;
  }

  .footerTop-menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .aboutContaianer {
    padding: 50px 12px;
  }

  .aboutContaianer h2 {
    font-size: 2.1rem;
    text-align: center;
  }

  .contentHeath_block_left {
    flex: 0 1 100%;
  }

  .contentHeath_block_left {
    flex: 0 1 100%;
  }

  li {
    text-align: center;
  }

  .contentHeathSpan .contentHeath_block_right .paragraphtext {
    padding: 0;
  }

  .seemorecontent {
    justify-content: center;
    padding-left: 0;
    margin-top: 20px;
  }

  .defpadding {
    padding: 26px 0px 3px;
  }

  .footerLft {
    text-align: center;
  }

  .footerRgt span {
    display: none;
  }

  .footerRgt {
    text-align: center;
    margin: 10px 0;
  }

  .banner_add .cardcontainer {
    padding: 10px;
  }

  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    display: none;
  }

  .specimen-wrap > .container .owl-carousel .owl-nav button.owl-prev,
  .specimen-wrap > .container .owl-carousel .owl-nav button.owl-next {
    display: block;
  }

  .banner_add {
    height: calc(50vh - 81px) !important;
    width: 100%;
  }

  .banner_add .cardcontainer {
    justify-content: center;
    align-items: baseline;
  }

  .sliderHeaderText,
  .sliderParaText {
    text-align: center;
  }

  .banner_add .seeDetails {
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translate(-50%, -50%);
  }

  .feature-three-blogs-w.feature-blog {
    padding: 0px;
  }
}

.custom-padding-left {
  padding-left: 2.25rem;
}
.feature-three-blogs-w .detailspnametext {
  font-size: 0.75rem;
}
.custom-padding {
  padding-left: 8px;
}

.feature-three-blogs-w .detailstext {
  padding: 0 0px;
  min-height: 60px;
  margin-bottom: 0;
}
.newsBg_des_left h2 {
  font-size: 1.5rem;
}
.searchbtn {
  font-size: 1.15rem;
}
/* .searchContainer {
      width: 85%;
    } */
.searchInput > input::placeholder {
  /* font-size: 14px;  */
}

.imagecontainer .common-block .sec-content .para-txt {
  font-size: 18px;
  line-height: normal;
}
.sec-content.slide {
  opacity: 1;
  overflow: visible;
  visibility: visible;
  transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
  background: rgba(0, 0, 0, 0.3);
}
h5.paragraphtext {
  font-size: 18px;
}
.workcontent {
  margin-top: 25px;
}
.goToTop {
  position: relative;
}
.goToTopBtn {
  position: absolute;
  bottom: 90px;
  right: 20px;
}
.ms-auto.nav-item > a > img {
  width: revert-layer;
}

@media (max-width: 600px) {
  .custom-padding-left {
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }
}
