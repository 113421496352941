.main {
  height: 100%;
}

.hdr-wrapper {
  background: #9e015d;
  display: flex;
  flex-direction: column;
  /* padding: 18px 0; */
}


.hdr-wrapper .nav .nav-link {
  padding: 19px 15px;
  color: #fff;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 2px solid transparent;
}

.hdr-wrapper .nav .nav-link:hover {
  color: #ff9b05;
  border-bottom: 2px solid #ff9b05;
}

.hdr-wrapper .nav .nav-link.active {
  color: #ffd660;
  padding: 19px 15px;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-bottom: 2px solid #ff9b05;
}

.hdr-wrapper .logo-wrp {
  text-align: left;
}

.searchBar {
  width: 13.1rem;
  margin-left: auto;
  height: 2.1rem;
  border-radius: 30px;
  background: #9e015d;
  background-image: url("../../src/assets/image/searchIcon.png");
  background-size: 1.25rem;
  background-repeat: no-repeat;
  background-position: center left 10px;
  border: 1px solid #fff;
  outline: none;
  padding-left: 45px;
  box-shadow: none;
  margin-bottom: 0;
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchBar::placeholder {
  color: #fff;
}

.searchBar:focus {
  box-shadow: none;
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.slider_wrapper {
  position: relative;
}

.slider_wrapper .btnback {
  z-index: 1;
  position: absolute;
  width: 16.325rem;
  height: 3.125rem;
  border-radius: 3.125rem;
  background: #ffd660;
  border-color: #ffd660;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  top: 2.5rem;
  left: 4rem;
}

.slider_wrapper .btnback:focus,
.slider_wrapper .btnback:hover {
  background: #ffd660;
  color: #61422c;
}

.slider_wrapper .slider-card {
  background: url("../assets/image/knBanner.jpg") no-repeat center center;
  background-size: cover;
  padding: 340px 150px 120px 130px;
  text-align: left;
  margin-bottom: 0;
  height: 620px;
}

.slider_wrapper .slider-card .card-title {
  color: #fff;
  font-family: "Sarabun", sans-serif;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 137%;
  margin-bottom: 15px;
}

.slider_wrapper .slider-card .card-subtitle {
  color: #fff;
  font-family: "Sarabun", sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 137%;
  min-height: 94px;
}

.slider_wrapper .slick-dots {
  position: absolute;
  bottom: 20px;
}

.slider_wrapper .slick-dots li {
  width: 5.625rem;
  height: 0.625rem;
  margin: 0 1rem;
}

.slider_wrapper .slick-dots li:first-of-type {
  margin-left: 0;
}

.slider_wrapper .slick-dots li:last-of-type {
  margin-right: 0;
}

.slider_wrapper .slick-dots li button {
  width: 100%;
  height: 100%;
}

.slider_wrapper .slick-dots li button:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 1;
  border-radius: 0.5rem;
}

.slider_wrapper .slick-dots li.slick-active button:before {
  background: #df6dab;
}

.popular-section {
  /* padding: 0 320px; */
  margin-top: 64px;
}

.sex-councel-hero {
  /* background-image: url("../assets/image/banner-04.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0px !important;
  margin: 0px !important;
  text-align: center;
  width: 100%;
  height: 500px;
}

.sex-councel-hero h1 {
  color: white;
  text-align: center;
  font-size: 8rem;
  font-weight: 800;
  z-index: 1;
}

.pillow-talk-hero {
  background-image: url("../assets/image/banner-03.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0px !important;
  margin: 0px !important;
  text-align: center;
  width: 100%;
  height: 500px;
}

.popular-section .heading {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 3.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 80px 6px 70px 6px;
}

.popular-section .heading span {
  color: #df6dab;
}

.popular-section .screen-image {
  width: 100%;
  height: 25vw;
  flex-shrink: 0;
  border-radius: 1.875rem;
  background: #d9d9d9;
  object-fit: cover;
}

.popular-section .card-body {
  margin-top: 30px;
}

.popular-section .card-body .detailsbtn {
  padding-left: 30px;
  padding-right: 30px;
  color: #fff;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.popular-section .card-body .paratxt {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* text-transform: uppercase; */
  /* word-break: break-all; */
  margin-top: 20px;
}

.popular-section .card-body .subtext {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0;
}

.tag_wrapper {
  border-top: 1px solid #785338;
  border-bottom: 1px solid #785338;
  margin-bottom: 20px;
}

.tag_wrapper .tagcard {
  margin-bottom: 0;
}

.tag_wrapper .tagcard .nav {
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.tag_wrapper .tagcard .nav .nav-item {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 137%;
}

.tag_wrapper .tagcard .nav .nav-item .nav-link {
  color: #61422c;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 137%;
}

.popular_video .video-card-wrap video {
  height: 100%;
}

.popular_video {
  /* padding-bottom: 60px; */
}

/* .popular_video .common-content {
  height: 520px;
  overflow-y: auto;
} */
.popular_video .common-content {
  height: 520px;
  /* overflow-y: auto; */
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  background-color: #666;
}

.popular_video .common-content iframe {
  /* width: 100%;
  border-radius: 5px; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.popular_video .show-more,
.popular_video .show-more:focus,
.popular_video .show-more:hover {
  flex-shrink: 0;
  border-radius: 3.125rem;
  background: #c37f4d;
  color: #fff;
  border-color: #c37f4d;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 25px;
}

.popular_video .related-video {
  background: #ffda6f;
  /* border-radius: 15px; */
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 21px;
  /* margin-bottom: 15px; */
}

.popular_video .main-video {
  height: 100%;
}

.popular_video .related-video .card-title {
  word-break: break-all;
  text-align: left;
  margin-right: 20px;
}

.popular_video .related-video .sqimg {
  width: 188px;
  height: 121px;
}

.footer {
  background: #9e015d;
  padding: 30px 0;
}

.footer .footer-card {
  border-radius: 0;
  background: transparent;
  text-align: left;
}

.footer .footer-card .card-title {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer .footer-card .card-subtitle {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer .footer-card .nav {
  align-items: center;
  margin-top: 20px;
}

.footer .footer-card .nav .nav-item {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.footer .privacy-policy {
  padding-top: 20px;
  border-top: 1px solid #fff;
  justify-content: center;
}

.footer .privacy-policy .nav-link {
  color: #fff;
}

.kn-dtls-page-content {
  padding: 30px 0px 100px;
  max-width: 90%;
  margin: 0 auto;
}

.kn-dtls-page-content .popular-section {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 40px;
}

.cs-breadcum .breadcrumb .breadcrumb-item,
.cs-breadcum .breadcrumb .breadcrumb-item a {
  color: #ffd660;
  font-family: "Cabin", sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.cs-breadcum .breadcrumb .breadcrumb-item.active {
  color: #61422c;
}

.cs-breadcum .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.kn-dtls-page-content .large-image img {
  width: 100%;
  height: 52.25rem;
  border-radius: 1.25rem;
}

/* .kd-content-card-wrap {
  padding: 90px 230px 0;
} */

.kd-content-card-wrap .kd-content-cards {
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
  transition: 0.3s;
}

.kd-content-card-wrap .kd-content-cards .detailsbtn {
  background: #57add7;
}

.kd-content-card-wrap .kd-content-cards .card-img-top {
  width: 50%;
  height: 28rem;
  border-radius: 1.25rem;
  object-fit: cover;
}

.kd-content-card-wrap .kd-content-cards .card-body {
  text-align: left;
  padding-right: 0;
  width: 50%;
}

.kd-content-card-wrap .card:hover {
  border: 1px solid #ea409c;
  background: rgb(234 64 156 / 10%);
  border-radius: 20px;
}

.kd-content-card-wrap .kd-content-cards .card-body .card-title {
  color: #785338;
  font-family: "Sarabun", sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 137%;
  text-transform: uppercase;
  margin: 16px 0;
}

.kd-content-card-wrap .kd-content-cards .card-body .paratxt {
  color: #785338;
  font-family: "Cabin", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: lowercase;
}

.kd-content-card-wrap .kd-content-cards .card-body .subtext {
  color: #785338;
  font-family: "Sarabun", sans-serif;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: right;
  margin-top: 50px;
}

.kd-content-card-wrap .pagination_wrap .pagination {
  justify-content: center;
  margin-top: 100px;
}

.kd-content-card-wrap .pagination_wrap .pagination .page-item {
  margin: 0 10px;
}

.kd-content-card-wrap
  .pagination_wrap
  .pagination
  .page-item.active
  .page-link {
  background: #df6dab;
  border-color: #df6dab;
  color: #fff;
}

.kd-content-card-wrap .pagination_wrap .pagination .page-item .page-link {
  width: 3rem;
  height: 3.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #61422c;
  border-radius: 0;
  background: #fff;
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.se-form-wrapper {
  border-radius: 1.875rem;
  border: 2px solid #9e015d;
  padding: 4rem;
  background: #fafafa;
  margin-top: 60px;
}

.se-form-wrapper .card-title {
  color: #9e015d;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.se-form-wrapper .card-subtitle {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.se-form-wrapper .social-media {
  justify-content: center;
  margin: 20px 0;
}

.se-form-wrapper .social-media .nav-item:not(:first-of-type) {
  margin-left: 30px;
}

.se-form-wrapper .social-media .nav-item .nav-link {
  padding: 0;
}

.se-form-wrapper .or {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 3rem 0;
}

.se-form-wrapper .contact-form .form-label {
  text-align: left;
  display: block;
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.se-form-wrapper .contact-form .form-label span {
  color: #df6dab;
}

.se-form-wrapper .contact-form .form-control {
  height: 3rem;
  border-radius: 0;
  padding: 10px 24px;
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.se-form-wrapper .contact-form textarea.form-control {
  height: 9.75rem;
  resize: none;
}

.se-form-wrapper .contact-form .hint-label {
  color: #9e015d;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.se-form-wrapper .detailsbtn {
  width: 11.8125rem;
  /* height: 3.125rem; */
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border-radius: 6.25rem;
  background: #9e015d;
  margin-top: 5rem;
}

.se-form-wrapper .detailsbtn:hover {
  background: #c5257d;
}

.blog-wrap-main .blog-banner {
  text-align: left;
  height: 994px;
  position: relative;
}

.blog-wrap-main .blog-banner .bblk-inner-part {
  font-family: "Cabin", sans-serif;
  padding: 40px 100px 100px;
  position: relative;
  height: 100%;
  width: 100%;
}

.blog-wrap-main .blog-banner .blg-bnnr-img {
  width: 100%;
  height: 100%;
  position: absolute;
}

.blog-wrap-main .blog-banner .backbtn {
  color: #fff;
  border-radius: 3.125rem;
  background: #9e015d;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  width: 12.5rem;
  height: 5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.blog-wrap-main .blog-banner .backbtn svg {
  margin-right: 1rem;
}

.blog-wrap-main .blog-banner .title {
  color: #000;
  text-align: center;
  /* font-family: "ABeeZee", sans-serif; */
  font-family: "Cabin", sans-serif;
  font-size: 6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 137%;
  padding: 0 380px;
  margin-bottom: 110px;
}

.blog-wrap-main .blog-content {
  padding: 50px 320px;
  text-align: left;
}

.blog-wrap-main .blog-content .detailsbtn {
  display: inline-block;
  min-width: 10.9375rem;
  height: 2.8125rem;
  text-align: center;
  border-radius: 1.875rem;
  color: #fff;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: #df6dab;
  margin-bottom: 20px;
}

.blog-wrap-main .blog-content .subtxt {
  color: #8b8989;
  font-family: "Cabin", sans-serif;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 184%;
}

.blog-wrap-main .blog-content .subtxt span {
  color: #000;
}

.blog-wrap-main .blog-content .cont-title {
  color: #000;
  /* font-family: "ABeeZee", sans-serif; */
  font-family: "Cabin", sans-serif;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 400;
  line-height: 137%;
  margin: 2rem 0;
}

.blog-wrap-main .blog-content .paratxt {
  color: #000;
  font-family: "Sarabun", sans-serif;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 165.5%;
  margin-bottom: 2rem;
}

.blog-wrap-main .blog-content .paraSubtxt {
  color: #000;
  font-family: "Sarabun", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 165.5%;
  margin-top: 2rem;
}

.blog-wrap-main .blog-content .tag_wrapper {
  border: none;
}

.blog-wrap-main .kd-content-card-wrap {
  padding: 90px 320px;
}

.blog-wrap-main .recent-article .rhead-wrp {
  border-bottom: 5px solid #9e015d;
  text-align: left;
  padding-left: 220px;
}

.blog-wrap-main .recent-article .r-title {
  color: #fff;
  text-align: justify;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  background: #9e015d;
  line-height: normal;
  padding: 32px 60px;
  display: inline-block;
}

.blog-wrap-main .kd-content-card-wrap .kd-content-cards {
  flex-direction: column;
}

.blog-wrap-main .kd-content-card-wrap .kd-content-cards .card-img-top {
  width: 100%;
  height: 320px;
}

.blog-wrap-main .kd-content-card-wrap .kd-content-cards .card-body {
  width: 100%;
  padding: 0;
}

.se-clinic .kn-dtls-page-content {
  padding-left: 320px;
  padding-right: 320px;
}

@media screen and (max-width: 1840px) {
  .se-form-wrapper {
    padding: 3rem;
    margin-top: 40px;
  }

  .slider_wrapper .slider-card {
    height: 520px;
    padding-top: 250px;
  }

  .slider_wrapper .slider-card .card-title {
    font-size: 3rem;
  }

  .slider_wrapper .slider-card .card-subtitle {
    font-size: 2.125rem;
  }

  .se-clinic .kn-dtls-page-content {
    padding-left: 220px;
    padding-right: 220px;
  }

  .blog-wrap-main .blog-content {
    padding: 50px 200px;
  }

  .blog-wrap-main .blog-banner .title {
    font-size: 5rem;
    padding: 0 200px;
  }

  .blog-wrap-main .blog-banner {
    height: 670px;
  }

  .blog-wrap-main .blog-banner .backbtn {
    font-size: 1.5rem;
  }
}

.slider_wrapper .slider-card {
  height: 445px;
}

.slider_wrapper .slider-card .card-title {
  font-size: 3rem;
}

.slider_wrapper .slider-card .card-subtitle {
  font-size: 2rem;
}

.footer .footer-card .card-subtitle {
  font-size: 1.5rem;
}

.footer .footer-card .card-title {
  font-size: 2rem;
}

.footer .footer-card .nav .nav-item {
  font-size: 1.25rem;
}

.popular-section .card-body .subtext {
  font-size: 1rem;
}

.tag_wrapper .tagcard .nav .nav-item {
  font-size: 1.5rem;
}

.tag_wrapper .tagcard .nav .nav-item .nav-link {
  font-size: 1.25rem;
}

.kd-content-card-wrap .kd-content-cards .card-img-top {
  width: 50%;
  height: 22rem;
  border-radius: 1.25rem;
}

.kn-dtls-page-content .large-image img {
  height: 30rem;
}

.cs-breadcum .breadcrumb .breadcrumb-item,
.cs-breadcum .breadcrumb .breadcrumb-item a {
  font-size: 1.5rem;
}

.kd-content-card-wrap .kd-content-cards .card-body .card-title {
  font-size: 1.5rem;
}

.kd-content-card-wrap .kd-content-cards .card-body .paratxt {
  font-size: 1.375rem;
}

.se-form-wrapper .card-title {
  font-size: 1.625rem;
}

.se-form-wrapper .card-subtitle {
  font-size: 1.375rem;
}

.se-form-wrapper .social-media .nav-item .nav-link {
  width: 60px;
  height: 55px;
}

.se-form-wrapper .or {
  font-size: 1.5rem;
  margin: 1.25rem 0;
}

.se-form-wrapper .contact-form .form-label {
  font-size: 1.25rem;
}

.se-form-wrapper .contact-form .form-control {
  /* height: 3.75rem; */
  font-size: 1.25rem;
  border-radius: 5px;
}

.se-form-wrapper .contact-form textarea.form-control {
  height: 10rem;
}

.se-form-wrapper .detailsbtn {
  width: 16rem;
  /* height: 4rem; */
  font-size: 1.5rem;
  margin-top: 2rem;
}

.blog-wrap-main .blog-banner .backbtn {
  height: 3.25rem;
  font-size: 1.125rem;
  width: 10rem;
}

.blog-wrap-main .blog-banner .title {
  font-size: 5rem;
  padding: 0 190px;
  color:#000;
}

.blog-wrap-main .blog-banner {
  height: auto;
}

.blog-wrap-main .blog-content .detailsbtn {
  font-size: 1.125rem;
}

.blog-wrap-main .blog-content .subtxt {
  font-size: 1.125rem;
  line-height: inherit;
}

.blog-wrap-main .blog-content .cont-title {
  font-size: 1.5rem;
  line-height: inherit;
}

.blog-wrap-main .blog-content .paratxt {
  font-size: 18px;
  line-height: inherit;
}

.blog-wrap-main .blog-content .paraSubtxt {
  font-size: 18px;
  line-height: inherit;
}

.blog-wrap-main .recent-article .r-title {
  font-size: 1.25rem;
  padding: 16px 30px;
}

.blog-wrap-main .recent-article .rhead-wrp {
  border-bottom-width: 3px;
  padding-left: 170px;
}

.blog-wrap-main .kd-content-card-wrap {
  padding: 70px 240px;
}

.blog-wrap-main .kd-content-card-wrap .kd-content-cards .card-img-top {
  width: 100%;
  height: 160px;
}

.kd-content-card-wrap .kd-content-cards .card-body .card-title {
  font-size: 1.125rem;
}

@media screen and (max-width: 1400px) {
  .se-form-wrapper {
    padding: 2rem;
    margin-top: 30px;
    border-radius: 1.25rem;
  }

  /* .hdr-wrapper .nav .nav-link {
    font-size: 1.125rem;
  } */

  .slider_wrapper .slick-dots li {
    width: 2.5rem;
    height: 0.5rem;
    margin: 0 0.5rem;
  }

  .slider_wrapper .btnback {
    width: 16rem;
    height: 3rem;
    font-size: 1.125rem;
  }

  .slider_wrapper .slider-card .card-title {
    font-size: 2rem;
  }

  .slider_wrapper .slider-card .card-subtitle {
    font-size: 1.5rem;
    min-height: 50px;
  }

  .slider_wrapper .slick-dots {
    bottom: 35px;
  }

  .slider_wrapper .slider-card {
    padding: 260px 150px 60px 65px;
  }

  .cs-breadcum .breadcrumb .breadcrumb-item,
  .cs-breadcum .breadcrumb .breadcrumb-item a {
    font-size: 1rem;
  }

  .kd-content-card-wrap {
    padding: 50px 200px 0;
  }

  .kd-content-card-wrap .kd-content-cards .card-img-top {
    height: 15rem;
  }

  .kd-content-card-wrap .pagination_wrap .pagination .page-item .page-link {
    font-size: 1.5rem;
  }

  .se-form-wrapper .card-title {
    font-size: 1.5rem;
  }

  .se-form-wrapper .card-subtitle {
    font-size: 1.25rem;
  }

  .se-form-wrapper .social-media .nav-item .nav-link {
    width: 38px;
    height: 36px;
  }

  .se-form-wrapper .social-media .nav-item:not(:first-of-type) {
    margin-left: 10px;
  }

  .se-form-wrapper .social-media {
    margin: 14px 0;
  }

  .se-form-wrapper .or {
    font-size: 1.25rem;
  }

  .se-form-wrapper .contact-form .form-label {
    font-size: 1.125rem;
  }

  .se-form-wrapper .contact-form .form-control {
    height: 2.5rem;
    font-size: 1.125rem;
  }

  .se-form-wrapper .contact-form textarea.form-control {
    height: 6rem;
  }

  .se-form-wrapper .detailsbtn {
    width: 12rem;
    /* height: 3rem; */
    font-size: 1.25rem;
    margin-top: 1rem;
  }

  .popular-section .card-body {
    margin-top: 20px;
  }

  .popular-section .card-body .detailsbtn {
    font-size: 1rem;
  }

  .popular-section .card-body .subtext {
    font-size: 0.875rem;
  }

  .tag_wrapper .tagcard .nav {
    padding: 16px 0;
  }

  .popular_video .related-video .sqimg {
    width: 100px;
    height: 96px;
  }

  .popular_video .common-content {
    height: 450px;
  }

  .footer .footer-card .card-subtitle {
    font-size: 1.25rem;
  }

  .footer .footer-card .nav .nav-item {
    font-size: 1.125rem;
  }
}

@media screen and (max-width: 1299px) {
  .slider_wrapper .slider-card {
    height: 330px;
    padding-top: 180px;
  }

  .slider_wrapper .slider-card .card-title {
    font-size: 1.5rem;
  }

  .slider_wrapper .slider-card .card-subtitle {
    font-size: 1.125rem;
  }

  .se-clinic .kn-dtls-page-content {
    padding-left: 15%;
    padding-right: 15%;
  }

  .blog-wrap-main .blog-banner .title {
    font-size: 3rem;
    padding: 0 100px;
  }

  .blog-wrap-main .blog-content {
    padding: 50px 120px;
  }

  .blog-wrap-main .recent-article .rhead-wrp {
    padding-left: 120px;
  }

  .blog-wrap-main .kd-content-card-wrap {
    padding: 70px 150px;
  }
}

@media screen and (max-width: 1199px) {
  .slider_wrapper .slick-dots {
    bottom: 20px;
  }

  .kd-content-card-wrap {
    padding: 50px 120px 0;
  }
}

@media screen and (max-width: 991px) {
  .blog-wrap-main .kd-content-card-wrap {
    padding: 70px 90px;
  }
}

@media screen and (max-width: 1134px) {
  .kn-dtls-page-content {
    padding: 30px 0px 70px;
  }

  .kd-content-cards {
    gap: 24px;
  }

  .kd-content-cards .card-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .searchBar {
    margin: 0 auto 10px;
    padding-left: 40px;
    width: 90%;
    height: 44px;
    max-width: 300px;
    font-size: 1.125rem;
  }

  .hdr-wrapper .nav .nav-link {
    font-size: 1.125rem;
  }

  .popular_video .common-content {
    height: auto;
  }

  .kn-dtls-page-content .large-image img {
    height: 25rem;
  }

  .kd-content-card-wrap .kd-content-cards .card-img-top {
    height: 20rem;
    width: 100%;
  }

  .kd-content-card-wrap .kd-content-cards .card-body {
    width: 100%;
    padding: 24px 0 0 0;
  }

  .kd-content-card-wrap {
    padding: 50px 40px 0;
  }

  .se-form-wrapper {
    padding: 2rem;
    border-radius: 1.25rem;
  }

  .se-form-wrapper .card-title {
    font-size: 1.5rem;
  }

  .se-form-wrapper .card-subtitle {
    font-size: 1.125rem;
  }

  .se-form-wrapper .detailsbtn {
    width: 100%;
    /* height: 3.5rem; */
    font-size: 1.5rem;
    border-radius: 6.25rem;
    margin-top: 2rem;
  }

  .se-form-wrapper .contact-form .form-label {
    font-size: 1.125rem;
  }

  .se-form-wrapper .contact-form .form-control {
    height: 3.5rem;
    font-size: 1.25rem;
    padding: 10px 16px;
  }

  .blog-wrap-main .kd-content-card-wrap,
  .blog-wrap-main .blog-content {
    padding: 60px 80px;
  }

  .blog-wrap-main .recent-article .rhead-wrp {
    padding-left: 50px;
  }

  .blog-wrap-main .blog-banner .title {
    font-size: 3rem;
    padding: 0;
    margin-top: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .slider_wrapper .slider-card {
    padding: 160px 30px 70px 30px;
  }

  .slider_wrapper .btnback {
    left: 0.5rem;
  }

  .kd-content-card-wrap .kd-content-cards {
    flex-direction: column;
  }

  .slider_wrapper .slider-card .card-title {
    font-size: 1.25rem;
  }

  .slider_wrapper .slider-card .card-subtitle {
    font-size: 1rem;
  }

  .se-clinic .kn-dtls-page-content {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media only screen and (min-width: 768px) {
  .padding-left0 {
    padding-left: 2px;
  }
}

.cs-breadcum .breadcrumb .breadcrumb-item,
.cs-breadcum .breadcrumb .breadcrumb-item a {
  font-size: 18px;
}
