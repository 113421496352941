.thankyouBase{
    text-align: center;
    padding: 0 15%;
}
.thankyouBase h2{
    color: #61422C;
}
.shippingBase{
    display: flex;
}
.shippignBlock{
    border: 1px solid #61422C;
    flex: 1;
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: left;
}
.shippignBlock img{
    width: 80px;
    margin-right: 30px;
}
.ordrList{
    text-align: left;
}
.ordrList h3{
    border-bottom: 1px solid #61422C;
    padding-bottom: 10px;
}
.odlBlock{
    display: flex;
    padding-top: 15px;
}
.odlBlock img{
    width: 150px;
    margin-right: 25px;
}
.odlBlock span{
    color: #BAA799;
}
.odlBtn{
    text-align: center;
}
.odlBtn button{
    border-radius: 5px;
    background-color: #61422C;
    padding: 15px 20px;
    width: 250px;
}
.orderSummary{
    background-color: #EDEDED;
    padding: 15px 0;
}



@media (max-width: 991px) {
    .thankyouBase {
        padding: 0;
    }
}
@media (max-width: 768px) {
    .orderSummary{
        margin-top: 25px;
    }
    .shippingBase{
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .odlBlock{
        flex-direction: column;
    }
    .odlBlock img{
        width: 100%;
        margin-right: 0;
        margin-bottom: 15px;
    }
    .shippignBlock{
        flex-direction: column;
        text-align: center;
    }
    .shippignBlock img{
        margin-right: 0;
        margin-bottom: 15px;
        max-width: 50px;
    }
}