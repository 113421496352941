.popular_video .show-more,
.popular_video .show-more:focus,
.popular_video .show-more:hover {
  padding: 8px 15px;
}
.popular_video .show-more,
.popular_video .show-more:focus,
.popular_video .show-more:hover {
  font-size: 1.1rem;
}

.searchBar {
  width: 9.1rem;
  background-size: 1.15rem;
  height: 1.9rem;
  font-size: 1rem;
}

/* @media screen and (max-width: 1600px)
.se-form-wrapper .contact-form .form-control {
    height: 2.75rem;
    font-size: 1rem;
  
} */

/* @media screen and (max-width: 1600px)
.se-form-wrapper .detailsbtn {
    
    height: 3.15rem;
    width: 12rem
    
} */

.kd-content-card-wrap {
  padding: 0;
}
.column-custom-justify {
  justify-content: space-between;
}
