.take-control .control-content h2 {
  font-size: 2rem;
}

.take-control .control-content p {
  font-size: 1.25rem;
  font-weight: 400;
}

.avaBg h2 {
  font-size: 2rem;
}
.workcontent h2 {
  font-size: 2rem;
}

.workcontent .bgcolordiv h4 {
  font-size: 1.5rem;
}
.workcontent .bgcolordiveven h4,
.workcontent .bgcolordiv h4 {
  font-size: 1.5rem;
}

.workcontent .bgcolordiveven p,
.workcontent .bgcolordiv p {
  font-size: 1.15rem;
}
.bgcolordiv h1 {
  font-size: 4rem;
}
.bgcolordiveven h1 {
  font-size: 4rem;
}
.workcontent .bgcolordiveven,
.workcontent .bgcolordiv {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  /* height: 50%; */
}
.specimen-wrap .speciment-blk h3 {
  font-size: 2rem;
}

.specimen-wrap h2 {
  font-size: 2rem;
}

.aboutdiv h3 {
  font-size: 2rem;
}
.specimen-wrap {
  padding: 25px 52px;
}

@media screen and (max-width: 960px) {
  .specimen-wrap {
    padding: 25px 35px;
  }
}
