.innerBreadcum {
  font-size: 18px;
  color: #61422c;
  font-weight: 400;
}

.innerBreadcum span {
  color: #ffd660;
  padding-right: 10px;
  display: inline-block;
}

.shippingBase span {
  color: #61422c;
  font-size: 16px;
  display: inline-block;
}

.myCrtbg {
  background-color: #ededed;
}

.crtTop {
  text-align: center;
  font-size: 28px;
  color: #fff;
  font-weight: 700;
  background-color: #ffd660;
  padding: 8px 0;
}

.crtTop img {
  width: 35px;
  margin-right: 10px;
}

.crtBtm {
  padding: 20px;
}

.ordrCart {
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

.ordrCart img {
  width: 160px;
  height: auto;
  margin-right: 20px;
}

.crtBtm .qtybtnbg button {
  line-height: 35px;
}

.crtBtm .qtybtnbg {
  border-radius: 8px;
}

.crtBtm .countspan {
  width: 50px;
}

.cartSubtotalbg {
  border-top: 1px solid #61422c;
  border-bottom: 1px dotted #61422c;
  padding: 15px 20px;
  margin: 15px 0;
}

.cartSubtotalbg p {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 5px;
}

.cartTotal {
  display: flex;
  justify-content: space-between;
  color: #61422c;
  font-size: 18px;
  padding: 0 20px;
}

.mycartsubmit button {
  width: 100%;
  border-radius: 5px;
  height: 50px;
  background-color: #61422c;
}

.mycartsubmit a {
  color: #fff;
}

/*  */
.textareainfo {
  height: 120px;
}

.word_count {
  text-align: right;
  padding: 0px 0px 0px;
}

.linkadd_text {
  position: relative;
}

.linkadd_text .form-control {
  padding-right: 50px;
}

.linkadd_text em {
  position: absolute;
  right: 2px;
  font-style: normal;
  font-size: 32px;
  color: #61422c;
  z-index: 10;
  padding: 5px 10px;
  line-height: 32px;
  cursor: pointer;
}

.def_btnwidth button {
  margin: 0 10%;
}

.def_btnwidth button {
  display: block;
  text-align: center;
  width: 16rem !important;
  margin: 0px auto;
}

.shippingadd img {
  width: 100%;
  height: 800px;
}

.shippingadd {
  position: relative;
  width: 100%;
}

/* .linkadd_text_custom {
  position: absolute;
  right: 2px;
  font-style: normal;
  font-size: 32px;
  color: #61422c;
  z-index: 10;
  padding: 5px 10px;
  line-height: 32px;
  cursor: pointer;
} */
.dynamic-div {
  position: relative;
}
.linkadd_text_custom em {
  position: absolute;
  right: 2px;
  bottom: -2px;
  font-style: normal;
  font-size: 32px;
  color: #61422c;
  z-index: 10;
  padding: 5px 10px;
  /* line-height: 32px; */
  cursor: pointer;
}

/*  */

@media (max-width: 768px) {
  .ordrCart {
    flex-direction: column;
  }

  .shippingadd {
    position: relative;
    margin-left: 0;
  }

  .shippingBase.affilates_info {
    flex-direction: column-reverse;
  }

  .affilates_infodes {
    padding-top: 0;
    margin-bottom: 50px;
  }
}
