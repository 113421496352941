.heading_info h1 {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 25px 0px;
  margin: 0px;
}
.heading_info h1 span {
  color: #ffd660;
  text-align: center;
}
.faq_content {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
}
.faq_item_opt {
  flex: 0 34%;
  margin-right: 2%;
}
.faq_item_view {
  flex: 1 1;
  background: #ededed;
  border-radius: 15px;
  padding: 25px;
}

.faq_item_info h2 {
  color: #61422c;
  font-family: Cabin;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.faq_item_info h2 span {
  display: block;
}
.faq_item_block {
  border-radius: 15px;
  background: #ededed;
  padding: 15px 20px 15px;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.faq_item_block.active_item {
  background: #f9d884 !important;
}
.faq_item_block_pic {
  width: 90px;
  height: 90px;
  background: #f9d884;
  border-radius: 50%;
  margin-right: 17px;
}
.faq_item_block_pic img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
}
.faq_item_block.active_item .faq_item_block_pic {
  background: #fff;
}
.faq_item_info p {
  margin: 0px;
  padding: 0px;
}
.faq_des_block .accordion-header .accordion-button {
  background: #ededed !important;
  border-radius: 0px !important;
  color: #61422c;
  font-family: Cabin;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.faq_des_block .accordion-header .accordion-button.collapsed:after {
  background: #f9d884 url(../assets/image/updatedicon/arrow1.png) no-repeat
    center center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-size: 12px;
}
.faq_des_block .accordion-header .accordion-button:after {
  background: #f9d884 url(../assets/image/updatedicon/arrow1.png) no-repeat
    center center;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-size: 12px;
}
.faq_des_block .accordion .accordion-item {
  margin-bottom: 0px;
  border-bottom: 1px solid #000;
}

.faq_des_block button.accordion-button {
  padding: 12px 0px;
}
.faq_des_block .accordion-body {
  color: #aa856a;
  padding-left: 0px;
  padding-right: 0px;
}
.accordion-item {
  background-color: #ededed;
}
.faq_des_block .accordion-collapse {
  background-color: #ededed;
  border-radius: 0px !important;
  background-repeat: no-repeat;
  padding-bottom: 25px;
}
.faq_des_block button.accordion-button {
  /* padding-left: 0px;
    padding-right: 0px; */
}

.faq_heading .faq_item_block {
  margin: 0px 0px 20px;
  padding: 0 0px;
}
.faq_item_info {
  flex: 1;
}

@media screen and (max-width: 1600px) {
  .heading_info h1 {
    font-size: 28px;
  }
}
@media (max-width: 1400px) {
}
@media (max-width: 1000px) {
  .faq_des_block .accordion-header .accordion-button {
    font-size: 17px;
  }
  .faq_item_info h2 {
    font-size: 20px;
  }
  .heading_info h1 {
    font-size: 28px;
  }
  .breadcrumbs {
    padding: 34px 0 20px;
  }
}

@media (max-width: 767px) {
  .faq_content {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .heading_info h1 {
    font-size: 24px;
  }
}
