.inner_slider_panel {
    padding:150px 0  100px;
    background-image: url('../assets/image/about-hero.png');
    background-size: cover;
    background-position: center;
  background-repeat: no-repeat;
  object-fit: stretch;
  width: 100%; 
  height: 400px;
  aspect-ratio: unset !important;
}

.mission-vision {
    background-image: url('../assets/image/Vision-Mission.png');
    background-size: cover;
    background-position: center;
  background-repeat: no-repeat;
  object-fit: stretch;
  width: 100%; 
  height: 500px;
  aspect-ratio: unset !important;
}

.inner_slider_panel .heading_info h2 {
    color: #ffffff;
    text-align: left;
    font-family: "Cabin", sans-serif;
    font-size: 34;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 25px 0px;
    margin: 0px;
}
.inner_slider_panel .heading_info h2 span {
    color: #61422C;
   display: inline-block;
}


.overview_info {
    padding:70px 0  100px;
    background: #fff;
}
.overview_info .heading_info {
    text-align: left;
}
.overview_info .heading_info h2 {
    text-align: left;
    font-size: 24px;
}
.overview_info .heading_info p {
    padding-bottom: 10px;
    margin: 0px;
}

.video_add {
    position: relative;
    /* overflow: hidden; */
    /* max-width: 800px; */
    /* padding-top: 30.25%; */
    margin: 45px auto;
    /* background: #D9D9D9; */
    border-radius: 22px;
    position: relative;

 }

  
  /* Then style the iframe to fit in the container div with full height and width */
  .video_iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 22px;
    
}
.play_button {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    text-align: center;
    background: #fff;
    border-radius: 50%;
    line-height: 40px;
    transform: translate(-50%, -50%);
    z-index: 100;
    cursor: pointer;
}
.heading2 h2{
    font-size: 30px;

}
.our_mission {
    padding:100px 0  100px;
    background: #D9D9D9;
}
.our_mission .heading_info h2 {
    text-align: center;
}

.content_add_block{
    padding:100px 0  100px;
}
.content_add_info .heading_info h2 {
    text-align: center;
}
.content_add_info {
    display: flex;
    justify-content: space-between;
    max-width: 800px;
    margin: 0px auto;
}

.content_add_des {
   flex: 0 49%;
   background: #D9D9D9;
   padding: 20px;
   border-radius: 15px;
   min-height: 400px;
}
@media(max-width:1000px) {
    .video_add {
        padding-top: 59.25%;

    }
}
@media(max-width:767px) {
    .content_add_info {

        flex-direction: column;
    }
    .content_add_des {
        margin-bottom: 24px;
    }


    .content_add_block,.overview_info {
        padding: 34px 0;
    }
}