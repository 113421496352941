.flowImg img {
  width: calc(100% - 20px);
}

.product-top-banner {
  position: relative;
  background: url("../assets/image/product-page-banner.jpg") no-repeat center
    center;
  background-size: cover;
  height: 330px;
}

.product-top-card {
  padding-left: 120px;
  padding-right: 120px;
}

.take-control {
  /* padding-bottom: 122px; */
}

.take-control .control-content {
  padding-top: 60px;
  text-align: center;
}

.take-control .control-content h2 {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 2.8rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  margin-bottom: 30px;
}

.take-control .control-content h2 span {
  color: #ffd660;
}

.take-control .control-content h3 {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 2.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: 4.5625rem;
  margin-bottom: 54px;
}

.take-control .control-content p {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem !important;
  font-style: normal;
  font-weight: 700;
  line-height: 2.5rem;
  margin-bottom: 0;
}

.take-control .content-bottom {
  flex-direction: row;
  padding-top: 50px;
  margin-bottom: 0;
}

.take-control .content-bottom .card-img {
  max-width: 50%;
  height: 470px;
  object-fit: cover;
}

.take-control .content-bottom .card-body {
  background: #ffecb4;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* padding: 30px 60px 120px 96px; */
  flex: 1;
}

.take-control .content-bottom .card-body .card-title {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 3rem;
  text-align: left;
}

.take-control .content-bottom .card-body .card-text {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2.9rem;
  text-align: left;
  margin-bottom: 2rem;
}

.avaBg {
  padding-top: 0px;
}

.avaBg h2 {
  color: #6fbba0;
  color: #785338;
  font-size: 2.25rem;
  line-height: normal;
  font-weight: 600;
  margin: 0;
  margin-bottom: 50px;
}

.avaBg small {
  font-size: 16px;
  font-weight: 400;
  color: #6fbba0;
}

.hpbtn {
  /* padding: 0 10px; */
  display: flex;
  gap: 10px;
}

.cartBtn {
  background-color: #d9d9d9;
  color: #fff;
  /* padding: 5px 20px; */
  /* margin-bottom: 5px; */
  display: block;
  /* width: 100%; */
  font-size: 16px;
}

.buyBtn {
  background-color: #6fbba0;
  color: #fff;
  /* padding: 15px 20px; */
  display: block;
  /* width: 100%; */
  font-size: 16px;
}

.infoTab {
  /* padding-bottom: 20px; */
}

.infoTab li {
  border-left: 1px solid #bcb7b7;
}

.infoTab li:first-child {
  border-left: 0;
}

.infoTab button {
  border-radius: 0 !important;
  background-color: transparent !important;
  border-bottom: 2px solid transparent;
  font-size: 17px;
  font-weight: 500;
  color: #bcb7b7;
  padding: 5px 0;
  margin: 0 25px;
}

.infoTab button.active,
.infoTab button:hover {
  border-color: #61422c;
  color: #61422c !important;
}

.tabContnt {
  padding: 20px;
}

.buyPlace {
  display: flex;
  justify-content: space-between;
}

.shopFind {
  background-color: #ededed;
  border-radius: 18px;
  padding: 20px;
  text-align: center;
  width: 30%;
  cursor: pointer;
}

.shopFind img {
  width: 180px;
}

.commonHdnflex {
  display: flex;
  justify-content: space-between;
}

.writeRev {
  display: inline-block;
  border-radius: 50px;
  padding: 2px 25px;
  background-color: #ffd660;
  color: #61422c;
  font-size: 15px;
  line-height: 36px;
  flex: none;
  cursor: pointer;
}

.writeRev:hover {
  background-color: #6fbba0;
  color: #fff;
}

.reviewBlock {
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
  display: flex;
  text-align: left;
}

.reviewBlock h5,
.reviewBlock p {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

/* .aboutdiv {
  max-width: 960px;
  margin: 50px 10%;
  text-align: left;
  margin-left: 192px;
} */
.aboutdiv {
  max-width: 80%;
  margin: 50px auto;
  padding: 0px !important;
}

.aboutdiv .card {
  flex-direction: row;
  margin-bottom: 0;
}

.aboutdiv .card .card-img-top {
  max-width: 45%;
  border-radius: 0;
}

.aboutdiv .card .card-body {
  text-align: left;
  padding: 0px 160px 85px 0;
}

.about-image {
  height: 476px;
}

.aboutdiv h3 {
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 6rem;
  text-transform: uppercase;
}

.aboutdiv p {
  font-family: "Cabin", sans-serif;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: 3.1875rem; */
}

.revCircle {
  margin-right: 15px;
  flex: none;
  width: 100px;
  height: 100px;
  background-color: #ccc;
  border-radius: 100px;
}

.reviewCarousel .owl-dot.active span {
  background-color: #ffd660 !important;
}

.otherprd img {
  width: 30%;
}

.reviewCarousel h3 {
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 4.375rem;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
}

.modalClosebtn {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 10px;
  background-color: #61422c;
  border-radius: 50px;
  color: #ffd660;
  opacity: 1;
  background-image: none !important;
  font-size: 20px;
  line-height: 22px;
}

.reviewModal small {
  font-style: italic;
  font-size: 13px;
}

.reviewModal form {
  text-align: left;
}

.reviewModal h6 {
  font-size: 18px;
  color: #61422c;
}

.reviewModal textarea {
  resize: none;
  height: 120px;
}

.reviewModal .commonButton {
  border-radius: 10px;
  background-color: #ffd660;
  color: #61422c;
  padding: 15px 30px;
}

.reviewModal .commonButton:hover {
  background-color: #6fbba0;
  color: #fff;
}

.avaproduct .ava-prod-contnt {
  cursor: pointer;
  position: relative;
  margin-bottom: 20px;
}

.avaproduct .ava-prod-contnt .main-img {
  /* height: 637px; */
  width: 100%;
  border-radius: 16px;
}

.avaproduct .ava-prod-contnt .badge {
  position: absolute;
  top: 2rem;
  left: 2rem;
  color: #fff;
  font-family: "Cabin", sans-serif;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  width: 11.0625rem;
  height: 3.1875rem;
  border-radius: 17px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.avaproduct .ava-prod-contnt .badge.bg-hpv {
  background: #6fbba0;
}

.avaproduct .ava-prod-contnt .badge.bg-sti {
  background: #57add7;
}

.avaproduct .ava-prod-contnt .details {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.875rem;
  color: #c37f4d;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.avaproduct .ava-prod-contnt .details img {
  display: none;
  animation-delay: 3.5s;
}

.avaproduct .ava-prod-contnt:hover .details {
  color: #785338;
}

.avaproduct .ava-prod-contnt:hover .details img {
  display: inline;
  transition: 0.5s;
}

.specimen-wrap {
  padding: 70px 52px;
}

.specimen-wrap button.owl-prev {
  left: -60px;
}

.specimen-wrap button.owl-next {
  right: -60px;
}

@media screen and (max-width: 768px) {
  .specimen-wrap button.owl-prev {
    left: 0px;
    top: 40px;
  }

  .specimen-wrap button.owl-next {
    right: 0px;
    top: 40px;
  }
  
  .specm-card.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.specimen-wrap .owl-theme .owl-dots .owl-dot span {
  width: 30px;
  height: 8px;
}

.specimen-wrap .owl-theme .owl-dots .owl-dot.active span {
  background: #7ba892;
}

@media screen and (min-width: 768px) {
  .specimen-wrap .item {
    padding: 0 45px;
  }
}

.specimen-wrap h2 {
  color: #61422c;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.specimen-wrap .speciment-blk h3 {
  color: #2d4130;
  font-family: "Cabin", sans-serif;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  margin: 54px 0;
}

.specimen-wrap button.owl-next::after {
  content: "\2192";
  border: none;
  color: #fff;
  font-size: 36px;
  height: 62px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -45%);
}

.specimen-wrap button.owl-prev::after {
  content: "\2190";
  border: none;
  color: #fff;
  font-size: 36px;
  height: 62px;
  width: 100%;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -45%);
}

.specimen-wrap button.owl-prev span,
.specimen-wrap button.owl-next span {
  background: #61422c;
}

.specimen-wrap button.owl-prev:hover span,
.specimen-wrap button.owl-next:hover span {
  background: #ffd660;
}

.specm-card .circle-img-wrp {
  display: flex;
}

.specm-card .circle-img-wrp .card-subtitle {
  color: #2d4130;
  font-family: "Cabin", sans-serif;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.27rem;
  margin-right: 0.3rem;
}

.specm-card .circle-img-wrp .img-blk {
  width: 175px;
  height: 175px;
  display: flex;
  justify-content: center;
  margin-bottom: -10px;
}

.specm-card .circle-img-wrp .img-blk img {
  width: 80%;
  height: 80%;
}

.specm-card .card-body {
  padding: 0px 10px 16px;
}

.specm-card .card-text {
  color: #61422c;
  text-align: center;
  font-family: "Cabin", sans-serif;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* margin-top: 3rem; */
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .avaBg h2 {
    font-size: 3rem;
    line-height: normal;
    margin-bottom: 30px;
  }

  .take-control .content-bottom {
    flex-direction: column;
    padding-top: 70px;
  }

  .aboutdiv .card {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }

  .aboutdiv .card .card-img-top {
    max-width: 100%;
    border-radius: 0;
  }

  .take-control {
    padding-bottom: 0px;
  }

  .take-control .control-content h2 {
    font-size: 2.4rem;
    line-height: normal;
  }

  .take-control .control-content h3 {
    font-size: 1.8rem;
    line-height: normal;
    margin-bottom: 33px;
  }

  .take-control .control-content p {
    font-size: 1.35rem;
    line-height: normal;
  }

  .take-control .content-bottom .card-img {
    max-width: 100%;
  }

  .take-control .content-bottom .card-body {
    padding: 57px 20px;
  }

  .take-control .content-bottom .card-body .card-text {
    font-size: 1.8rem;
    line-height: normal;
  }

  .product-top-card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .workcontent {
    margin-top: 36px;
  }

  .workcontent h2 {
    font-size: 3rem;
    margin-bottom: 27px;
  }

  .specimen-wrap {
    padding: 20px;
  }

  .specimen-wrap h2 {
    font-size: 2.7rem;
  }

  .aboutdiv {
    max-width: 100%;
    margin: 30px auto;
    padding: 20px !important;
  }

  .aboutdiv .card .card-body {
    text-align: justify;
    padding: 0;
  }

  .aboutdiv h3 {
    font-family: "Cabin", sans-serif;
    font-size: 2.8rem;
    line-height: normal;
    margin-bottom: 29px;
    text-align: center;
  }

  .aboutdiv p {
    font-size: 1.4rem;
    line-height: normal;
  }
}

/* @media (max-width: 1840px) {
  .aboutdiv {
    padding-left: 150px;
  }
  .reviewCarousel h3,
  .aboutdiv h3 {
    font-size: 3rem;
    line-height: normal;
    margin-bottom: 2.5rem;
  }
  .aboutdiv p {
    font-size: 2rem;
  }
  .aboutdiv .card .card-body {
    padding: 60px 90px 60px 0;
  }
  .product-top-banner {
    height: 754px;
  }
  .product-top-banner .innerBreadcum {
    font-size: 1.5rem;
  }
  .take-control .control-content p {
    font-size: 2rem;
    line-height: 3rem;
  }
  .take-control .content-bottom .card-body {
    padding: 60px 60px 80px 80px;
  }

  .take-control .content-bottom .card-body .card-text {
    font-size: 1.875rem;
    line-height: 3rem;
  }
  .take-control .content-bottom .card-body .card-title {
    font-size: 2.25rem;
    line-height: 3rem;
  }
  .specimen-wrap .item {
    padding: 0 120px;
  }
}
@media (max-width: 1600px) {
  .product-top-banner {
    height: 675px;
  }
  .product-top-card {
    padding-left: 250px;
    padding-right: 250px;
  }
  .avaBg h2,
  .workcontent h2 {
    font-size: 48px;
  }
  .reviewCarousel h3 {
    font-size: 2.5rem;
  }
  .aboutdiv h3 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 1.5rem;
  }
  .aboutdiv p {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .reviewBlock h5,
  .reviewBlock p {
    font-size: 1rem;
  }
  .take-control {
    
    padding-bottom: 0px;
  }
  .take-control .control-content h2 {
    padding-top: 60px;
    font-size: 2.5rem;
    line-height: normal;
    margin-bottom: 1rem;
  }
  .take-control .control-content h3 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
  }
  .take-control .control-content p {
    font-size: 1.125rem;
    line-height: 2rem;
  }
  .avaproduct .ava-prod-contnt .main-img {
    height: 460px;
    border-radius: 30px;
  }
  .avaproduct .ava-prod-contnt .badge {
    font-size: 1.5rem;
    width: 8rem;
    height: 2.875rem;
  }
  .avaproduct .ava-prod-contnt .details {
    font-size: 1.5rem;
  }

  .avaproduct .ava-prod-contnt:hover .details img {
    width: 30px;
  }
  .specimen-wrap h2 {
    font-size: 3rem;
  }
  .specimen-wrap .speciment-blk h3 {
    font-size: 3rem;
  }
  .specm-card .circle-img-wrp .card-subtitle {
    font-size: 4rem;
  }
  .specm-card .circle-img-wrp .img-blk {
    width: 240px;
    height: 240px;
  }
  .specm-card .card-text {
    font-size: 1.5rem;
  }
}
@media (max-width: 1400px) {
  .product-top-banner {
    height: 508px;
  }
  .aboutdiv {
    padding-left: 100px;
  }
  .aboutdiv .card .card-body {
    padding: 60px 60px 60px 0;
  }
  .take-control .content-bottom {
    padding-top: 50px;
  }
  .take-control .content-bottom .card-body {
    padding: 50px 50px 60px 40px;
  }
  .take-control .content-bottom .card-body .card-text {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .take-control .content-bottom .card-body .card-title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .avaBg {
    padding-top: 32px;
  }
  .avaBg h2,
  .workcontent h2,
  .specimen-wrap h2,
  .specimen-wrap .speciment-blk h3 {
    font-size: 36px;
  }
  .specimen-wrap .speciment-blk h3 {
    margin-top: 2rem;
  }
  .specm-card .card-text {
    font-size: 1.25rem;
  }
}
@media (max-width: 1199px) {
  .product-top-banner {
    height: 400px;
  }
  .product-top-card {
    padding-left: 150px;
    padding-right: 150px;
  }
  .specm-card .circle-img-wrp .card-subtitle {
    font-size: 3rem;
  }
  .specm-card .circle-img-wrp .img-blk {
    width: 180px;
    height: 180px;
  }
  .specimen-wrap .item {
    padding: 0;
  }
}
@media (max-width: 991px) {
  .take-control .content-bottom {
    flex-direction: column;
  }
  .take-control .content-bottom .card-body {
    padding: 2rem;
  }
  .take-control .content-bottom .card-img {
    max-width: 100%;
  }
  .take-control .content-bottom .card-body .card-title {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  .take-control .content-bottom .card-body .card-text {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .updated-cont-vid {
    padding-top: 0;
  }
  .customer-rev-wrap.reviewCarousel .paddingcls {
    padding: 54px 0 54px 50px;
  }
  .reviewCarousel h3 {
    font-size: 1.875rem;
  }
  .specimen-wrap button.owl-prev {
    left: -27px;
  }
  .specimen-wrap button.owl-next {
    right: -27px;
  }
  .product-top-card {
    padding-left: 5%;
    padding-right: 5%;
  }
  .aboutdiv {
    padding-left: 50px;
  }
}

@media (max-width: 768px) {
  .product-top-banner {
    height: 270px;
    padding: 24px;
  }

  .aboutdiv .card {
    flex-direction: column;
  }
  .aboutdiv {
    padding-left: 0px;
  }
  .aboutdiv .card .card-body {
    padding: 40px;
  }
  .aboutdiv .card .card-img-top {
    max-width: 100%;
  }
  .infoTab {
    flex-direction: column;
  }
  .infoTab li {
    border: 0;
    padding-bottom: 5px;
  }
  .take-control .control-content p br {
    display: none;
  }
  .avaproduct .ava-prod-contnt {
    margin-bottom: 20px;
  }
}
@media (max-width: 600px) {
  .footerTop .paddingcls {
    padding: 0;
  }
  .aboutdiv .card .card-body {
    padding: 30px;
    padding-top: 0;
  }
  .aboutdiv h3 {
    font-size: 2rem;
  }
  .specimen-wrap {
    padding: 52px 32px;
  }
  .specimen-wrap h2,
  .specimen-wrap .speciment-blk h3 {
    font-size: 30px;
  }
  .specimen-wrap .speciment-blk h3 {
    margin-top: 1rem;
  }
  .specm-card .circle-img-wrp .img-blk {
    width: 110px;
    height: 110px;
  }
  .specm-card .circle-img-wrp .card-subtitle {
    font-size: 3rem;
    margin-right: 0;
  }
  .specm-card .card-body {
    padding: 0;
  }
  .specm-card .card-text {
    font-size: 1rem;
    margin-top: 1rem;
  }
} */
